.user-prompt-template-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.user-prompt-template-label{
    width: 50px;
}
.user-prompt-template-expand{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.user-prompt-template-textArea{
    width: calc(100% - 110px);
}

.user-prompt-template-list-textarea-box {
    width: 100%;
    height: 75px;
    border-radius: 5px;
    
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    resize: none;
    border: 1px solid #000000;
}
.user-prompt-template-wrapper{
    border-width: 1px;
    border-radius: 5px;
    /* border: 1px solid #000000; */
    padding: 10px;
}
.user-prompt-template-add-remove-box
{
    display: flex;
    justify-content: flex-end;
}
.user-prompt-template-add-remove-box > .reading-list-action-text{
    cursor: pointer;
}
.user-prompt-template-prompt-area
{
    overflow-y: auto;
    max-height: 41vh;
    border: 1px solid #000000;
    padding: 2px;
    border-radius: 5px;
}

@media screen and (min-height: 440px) and (max-height: 494px) {
    .user-prompt-template-prompt-area {
        max-height: 36vh;
    }
    .prompt-templete-list-gsfModalHeader{
        padding: 2px 5px;
    }
    .prompt-templete-list-head-body{
        padding-top: 2px;
    }
    .prompt-templete-list-gsf-popup-button-wrapper{
        padding: 5px;
    }
}
@media screen and (min-height: 495px) and (max-height: 550px) {
    .user-prompt-template-prompt-area {
        max-height: 38vh;
    }
    .prompt-templete-list-gsfModalHeader{
        padding: 0.5%;
    }
    .prompt-templete-list-head-body{
        padding-top: 2px;
    }
    .prompt-templete-list-gsf-popup-button-wrapper{
        padding: 5px;
    }
}
@media screen and (min-height: 551px) and (max-height: 600px) {
    .user-prompt-template-prompt-area {
        max-height: 40vh;
    }  
    .prompt-templete-list-gsfModalHeader{
        padding: 0.5%;
    }
    .prompt-templete-list-head-body{
        padding-top: 2px;
    }
    /* .prompt-templete-list-gsf-popup-button-wrapper{
        padding: 5px;
    } */
}