.gen-ai-answering-container {
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.gen-ai-answering-sidebar {
    height: 100%;
    width: 200px;
    background-color: #efefef;
}

.gen-ai-answering-menuitem-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.gen-ai-answering-menuitem-header>img,
.gen-ai-answering-menuitem-arrow-left-to-line {
    cursor: pointer;
}

.gen-ai-answering-menuitem-arrow-left-to-line {
    padding: 5px;
    margin-left: 2px;
}

.gen-ai-answering-menuitem-arrow-left-to-line:hover {
    background-color: #e8e8e3;
    border-radius: 9999px;
}

.gen-ai-answering-menuitem-home,
.gen-ai-answering-menuitem-library {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.gen-ai-answering-text-align-left {
    margin-left: 5px;
}

.gen-ai-answering-menuitem-new-thread-collapsed>button {
    border-radius: 9999px;
    padding: 10px;
}

.gen-ai-answering-menuitem-new-thread-expand>button {
    width: 90%;
    padding: 5px 0;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    font-weight: 600;
}

.gen-ai-answering-menuitem-new-thread-expand>button:hover {
    border: 1px solid #033776;
}

.gen-ai-answering-menuitem-new-thread-collapsed,
.gen-ai-answering-menuitem-new-thread-expand {
    margin: 10px 0px;
}

.gen-ai-answering-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gen-ai-answering-main-img>img {
    width: 350px;
}



.gen-ai-answering-main-close {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
}

.gen-ai-answering-main-close {
    padding: 10px;
}

.gen-ai-answering-main-close>div {
    padding: 2px;
}

.gen-ai-answering-main-close>div:hover {
    background-color: #e8e8e3;
    border-radius: 9999px;
}

.gen-ai-answering-main-section {
    height: calc(100vh - 40%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.gen-ai-answering-main-ask-question-wrapper {
    height: 25%;
    width: 750px;

}

body>div.fade.document-processing-newthread.modal.show>div.modal-dialog>div.modal-content>div.modal-body>.gen-ai-answering-main-ask-question-wrapper {
    width: 100%;
}

.gen-ai-answering-main-ask-question>textarea {
    margin: 0;
    height: 100%;
    font-size: 17px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #000000;
    width: 100%;
    padding: 8px;
    color: #000000;
    /* font-weight: 700; */
}

.gen-ai-answering-main-ask-question {
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #000000;
}

.gen-ai-answering-main-ask-question>textarea {
    border: none;

}

.gen-ai-answering-main-ask-question {
    /* height: calc(100% - 25px); */
    display: flex;
    flex-direction: column;
    height: 150px;
}

.gen-ai-answering-main-action-section {
    background: #fff;
    padding: 0px 2px 0px 2px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-main-action-section-dropdown-wrapper {
    padding: 2px 10px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.gen-ai-answering-main-action-section-dropdown-focus {
    padding: 5px 10px 5px 7px;
    font-size: 14px;
}

.gen-ai-answering-main-action-section-file {
    padding: 5px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.gen-ai-answering-main-action-section-dropdown-focus:hover,
.gen-ai-answering-main-action-section-file:hover {
    background-color: #e8e8e3;
    color: #13343b;
    border-radius: 100px;
    cursor: pointer;
}


.gen-ai-answering-main-action-section-listfile>button {
    color: #ffffff;
}

.gen-ai-answering-main-action-section-send,
.gen-ai-answering-main-search-action-section-send {
    border-radius: 9999px;
    padding: 5px;
    background-color: #ffffff;
}

.gen-ai-answering-main-search-action-section-send {
    margin-right: 5px;
}

.gen-ai-answering-main-action-section-send:enabled:hover,
.gen-ai-answering-main-search-action-section-send:enabled:hover {
    background-color: #e8e8e3;
    color: #ffffff;
}

.gen-ai-answering-main-img {
    margin-bottom: 25px;
    margin-top: -50px;
}

.gen-ai-answering-main-focus-wrapper {
    z-index: 100;
    /* bottom: -5px; */
    background-color: #fff;
    padding-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    position: relative;
    /* bottom: 127px; */
    /* top: -111px; */


}

.gen-ai-answering-main-focus-close {
    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.gen-ai-answering-main-focus-open {

    display: block;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.gen-ai-answering-main-focus-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 750px;
}

.gen-ai-answering-main-focus-item {
    width: 240px;
    border: 2px solid #033776;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #033776;
    /* outline: 1px solid; */
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.gen-ai-answering-main-focus-selected {
    background-color: #033776;
    color: #ffffff;
}

.gen-ai-answering-main-focus-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 5px;
}

.gen-ai-answering-collection-threads-askquestion-section>.gen-ai-answering-main-ask-question-wrapper>.gen-ai-answering-main-attachedfile-wrapper {
    position: absolute;
    display: flex;
    z-index: 10000;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    bottom: inherit;
    width: 350px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    height: 180px;
}

div.fade.document-processing-newthread.modal.show>.modal-dialog>.modal-content>.modal-body>.gen-ai-answering-main-ask-question-wrapper>div.gen-ai-answering-main-focus-wrapper {
    position: absolute;
    bottom: -96px;
}

div.fade.document-processing-newthread.modal.show>.modal-dialog>.modal-content>.modal-body>.gen-ai-answering-main-ask-question-wrapper>div.gen-ai-answering-main-attachedfile-wrapper {
    position: absolute;
    bottom: -168px;
}

.gen-ai-answering-text-align-left {
    font-weight: 600;
}

.gen-ai-answering-library-main {
    width: 100%;
}


.gen-ai-answering-library-header {
    display: flex;
    padding: 10px 5px 10px 25px;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    border-color: hsla(60, 11%, 83%, .5);
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-library-header-h1-main {
    display: flex;
}

.gen-ai-answering-library-header-h1 {
    font-size: 1.5rem;
}

.gen-ai-answering-collection-threads-header-h1 {
    font-size: 1.5rem;
}

.gen-ai-answering-collection-threads-collection-detail-h1 {
    font-size: 1.5rem;
    text-align: left;
}

.gen-ai-answering-library-grid-cols-12 {
    display: flex;
}

.gen-ai-answering-library-threads-title,
.gen-ai-answering-library-threads-controle,
.gen-ai-answering-library-collections-title,
.gen-ai-answering-library-collections-controle {
    display: flex;
}

.gen-ai-answering-library-threads-title-wrapper,
.gen-ai-answering-library-collections-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    border-color: hsla(60, 11%, 83%, .5);
    font-weight: 700;
    font-size: 18px;
}

.gen-ai-answering-library-col-span-8 {
    width: 60%;
    padding: 10px;
}

.gen-ai-answering-library-col-span-4 {
    width: 40%;
    padding: 10px;
}

.gen-ai-answering-library-threads-list,
.gen-ai-answering-library-collections-list {
    background-color: #f3f3ee;
}

.gen-ai-answering-library-threads-list-no-threads,
.gen-ai-answering-library-collections-list-no-threads {
    padding: 30px;
    margin-top: 20px;

}

.gen-ai-answering-library-threads-controle>div {
    margin-left: 10px;
}

.gen-ai-answering-library-threads-controle button,
.gen-ai-answering-library-collections-controle button {
    width: 30px;
    height: 30px;
    padding: 3px;

}

.css-nwj8xd>.ps-menu-button:hover {
    background-color: #dfdcdc !important;
}

.gen-ai-answering-menuitem-selected,
.gen-ai-answering-menuitem-selected>.ps-menu-button:hover {
    background-color: #033776 !important;
    color: #ffffff !important;
}

.gen-ai-answering-search-main {
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.gen-ai-answering-search-question {
    display: flex;
    padding: 25px;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    border-color: hsla(60, 11%, 83%, .5);
    width: 100%;
}

.gen-ai-answering-result-question-h2 {
    font-size: 1.375rem;
    text-align: left;
    margin-bottom: 0px;
    padding-top: 2px;
}

.gen-ai-answering-graph-question-h2 {
    font-size: 1.0rem;
    text-align: left;
    margin-bottom: 0px;
    padding-top: 2px;
}

.gen-ai-answering-result-left {
    width: 70%;
    padding: 5px;
}

.gen-ai-answering-result-image-left {
    width: 100%;
    padding: 5px;
}

.gen-ai-answering-result-right {
    width: 30%;
    padding: 5px;

}

.gen-ai-answering-result-question {
    padding: 20px 20px 5px 20px;
    display: flex;
}

.gen-ai-answering-graph-question {
    padding: 5px 20px 5px 20px;
    display: flex;
}

.gen-ai-answering-result-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
}

.gen-ai-answering-result-source-header,
.gen-ai-answering-result-main-answer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    font-weight: 700;
}

.gen-ai-answering-result-main-answer-header {
    min-height: 42px;
}

.gen-ai-answering-result-source-main,
.gen-ai-answering-result-main-answer-main {
    padding: 5px 20px;
}

.gen-ai-answering-result-main-answer-main-relevant-information {
    padding: 5px 20px 0px 20px;
}

.gen-ai-answering-result-source-items-wrapper {
    padding: 5px 0;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
}

.gen-ai-answering-result-source-items-wrapper::-webkit-scrollbar {
    height: 10px !important;
}

.gen-ai-answering-result-source-items {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.gen-ai-answering-result-source-item {
    width: 180px;
}

.gen-ai-answering-result-source-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 34px;
    font-size: 12px;
    color: #0074b0;
    text-decoration: none;
    word-break: break-word;
}

.gen-ai-answering-result-recommendation-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    max-height: 40px;
    font-size: 14px;
    color: #0074b0;
    text-decoration: none;
    word-break: break-word;
}

.gen-ai-answering-result-recommendation-item-title:hover,
.gen-ai-answering-result-source-item-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.gen-ai-answering-result-source-item {
    align-items: flex-start;
    background-color: #f3f3ee;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    margin: 5px;
    padding: 5px;
    text-align: left;
}


.gen-ai-answering-result-recommendation-item-docnumber {
    font-size: 14px;
}

.gen-ai-answering-result-main-answer-wrapper {
    text-align: left;
}

.gen-ai-answering-result-main-answer-wrapper-relevant-information {
    text-align: left;
    padding: 0px 20px 5px;
}

.gen-ai-answering-search-textarea-main {
    padding: 8px;
    --tw-bg-opacity: 1;
    position: fixed;
    bottom: 5px;
    z-index: 1700;
    width: 55%;
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
}

.gen-ai-answering-search-textarea-main-controle {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #13343b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #ffff;
    border-radius: 9999px;
    box-shadow: 0 3px 6px #00000029;
    border: 2px solid #d9d9d0;
}

.gen-ai-answering-search-textarea-main-controle-textarea {
    width: 90%;
    border: none;
    resize: none;
    padding: 8px;
    font-weight: 600;
}

.gen-ai-answering-search-textarea-main-controle-file-label {
    padding: 5px;
    border-radius: 9999px;
}

.gen-ai-answering-search-textarea-main-controle-file-label:hover {
    background-color: #e8e8e3;
}

.gen-ai-answering-search-textarea-main-controle-send {
    border-radius: 9999px;
    padding: 5px;
}

.gen-ai-answering-search-textarea-main-controle-send:enabled:hover {
    background-color: #033776;
    color: #ffffff;
}

.gen-ai-answering-search-textarea-main-controle-file {
    margin-left: 5px;
}

.gen-ai-answering-search-textarea-main-controle-send-wrapper {
    margin-right: 5px;
}



.gen-ai-answering-main-attachedfile-wrapper {
    /* position: relative; */
    display: flex;
    z-index: 10000;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* bottom: -5px; */
    width: 350px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    height: 180px;
    position: absolute;
    /*  bottom: 105px; */
    z-index: 50000;
}

.gen-ai-answering-main-attachedfile-items {
    overflow-y: auto;
    height: 130px;
    width: 100%;
}

.gen-ai-answering-main-attachedfile-close {

    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.gen-ai-answering-main-attachedfile-open {
    display: block;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.gen-ai-answering-library-header-div {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.gen-ai-answering-collection-threads-header-div {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.gen-ai-answering-main-attachedfile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000000;
    padding: 0px 5px 5px 5px;
    width: 100%;
}

.gen-ai-answering-main-attachedfile-header-controle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-main-attachedfile-header-controle-add>div,
.gen-ai-answering-main-attachedfile-header-controle-delete>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-main-attachedfile-header-title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #13343b;

}

.gen-ai-answering-main-attachedfile-header-controle-delete {
    background-color: #ffffff;
}

.gen-ai-answering-main-attachedfile-item {
    text-align: left;
    padding: 5px;
    font-size: .875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-main-attachedfile-item-span {
    color: #000000;
}

.gen-ai-answering-search-main-attachedfile-wrapper {
    position: fixed;
    z-index: 10000;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 120px;
    left: 42.3%;
    width: 350px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    height: 180px;
}

.gen-ai-answering-result-recommendation-header-title {
    color: #13343b;
    font-weight: 700;
}

.gen-ai-answering-result-recommendation-header,
.gen-ai-answering-result-combined-summary-header {
    padding: 20px 10px 10px 10px;
}

.gen-ai-answering-result-combined-summary-header-title {
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gen-ai-answering-result-recommendation-header-title,
.gen-ai-answering-result-combined-summary-header-title,
.gen-ai-answering-result-recommendation-item {
    text-align: left;
}

.gen-ai-answering-result-recommendation-items-wrapper {
    padding: 0px 10px 10px 10px;
}

.gen-ai-answering-result-recommendation-item-hr {
    margin: 0.5rem 0;
}

/* 
.gen-ai-answering-result-recommendation-item-preview-link {

    color: #000000 !important;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}

.gen-ai-answering-result-recommendation-item-preview-link:hover {
    color: #0074b0 !important;
} */

.gen-ai-answering-result-recommendation-item-preview-panel {
    /* display: flex; */
    background: #fff;
    border: 1px solid #0074b0;
    border-radius: 5px;
    color: #0074b0 !important;
    cursor: pointer !important;
    margin: 6px auto 4px !important;
    padding: 1px 17px;
    width: 100px;
    text-align: center;
    font-size: 14px;
}

.gen-ai-answering-result-recommendation-item-preview-panel:hover {
    background: #0074b0;
    border: 1px solid #fff;
    color: #fff !important;
}

.stickyHeaderExpand {
    position: fixed;
    top: 0;
    width: calc(100% - 210px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.stickyHeaderCollapse {
    position: fixed;
    top: 0;
    width: calc(100% - 90px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 1;

}

.gen-ai-answering-search-main>header {
    height: 50px;
}

.gen-ai-answering-search-main>header,
.gen-ai-answering-library-main>header,
.gen-ai-answering-collection-thread-main>header {
    background-color: #ffffff;
    border: 1px solid #d8d8cf80;
}

.gen-ai-answering-search-main-containers {
    display: flex;
}

.gen-ai-answering-search-main-header-collection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: #64645f;
}

.gen-ai-answering-search-main-header-collection-question {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gen-ai-answering-search-main-header-collection-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #64645f;
}

.gen-ai-answering-menuitem-thread-link {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #64645f !important;
    display: -webkit-box;
    font-size: .8rem;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    word-break: break-word;
    min-height: 20px;
    width: 80%;
    text-align: left;
}

.gen-ai-answering-library-header-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gen-ai-answering-menuitem-thread-history-item {
    display: flex;
    padding: 4px;
    border-radius: .25rem;
    height: 28px;
}

.gen-ai-answering-menuitem-thread-history-item:hover {
    background-color: #e8e8e3;
}

.gen-ai-answering-menuitem-thread-history {
    width: 162px;
    margin-left: 32px;
    border-left: 1px solid #64645f;
    max-height: 220px;
    overflow-x: auto;
    scrollbar-width: thin;
}

.gen-ai-answering-menuitem-new-thread-history-item>svg {
    display: none;
}

.gen-ai-answering-result-main-answer-source-label,
.gen-ai-answering-result-main-answer-sup,
.gen-ai-answering-preview-window-source-label {
    padding: 4px 8px;
    text-align: center;
    cursor: pointer;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #00000033;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    margin: 0px 2px;
    min-width: 14px;
    height: 16px;
    width: 16px;
    text-decoration-color: transparent;
    outline: transparent solid 1px;
    color: #033776;
    font-size: 10px;
    font-weight: 700;
}

.gen-ai-answering-result-main-answer-sup {
    top: -1px;
}

.gen-ai-answering-result-main-answer-source-label {
    top: 3px;
}

.gen-ai-answering-preview-window-source-label {
    top: 6px;
}

.gen-ai-answering-result-main-answer-source-label:hover,
.gen-ai-answering-result-main-answer-sup:hover,
.gen-ai-answering-preview-window-source-label:hover {
    background: #eff3ff;
    border-color: #033776;
    color: #033776;
}

.gen-ai-answering-library-thread-item-title-link {
    color: #13343b !important;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
}

.gen-ai-answering-library-thread-item-title {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 40px;
}

.gen-ai-answering-library-thread-item {
    padding: 16px 5px;
    /* padding-top: 16px;
    padding-bottom: 16px; */
    border-bottom: 1px solid #e5e7eb;
}

.gen-ai-answering-library-thread-item-ans {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 40px;
    color: #64645f !important;
    text-decoration: none;
}

.gen-ai-answering-library-thread-item-graph {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 2px;
    color: #64645f !important;
    text-decoration: none;
}

/* .gen-ai-answering-library-threads-lists {
    height: 600px;
    overflow-x: auto;
} */

.gen-ai-answering-library-main {
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.gen-ai-answering-library-thread-item-time {
    display: flex;
}

.gen-ai-answering-library-thread-item-time-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-library-thread-item-action {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gen-ai-answering-library-thread-item-action-add,
.gen-ai-answering-library-thread-item-action-menu {
    background: none;
}

.gen-ai-answering-library-thread-item-contextMenu {
    padding: 4px;
    border: 1px solid #000000;
    box-shadow: 0 3px 6px #00000029;
    text-align: left;
    background-color: #ffffff;
    z-index: 2;
}

.gen-ai-answering-library-thread-item-contextMenu>.react-contextmenu-item {
    padding: 4px;
    cursor: pointer;
}

.gen-ai-answering-library-thread-item-contextMenu>.react-contextmenu-item:hover {
    background-color: #00000029;
}

.gen-ai-answering-result-source-item-citations {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.gen-ai-answering-result-source-item-citation-lable-custome {
    display: flex;
    align-items: center;
}

.gen-ai-answering-result-main-answer-label {
    background-color: lightblue;
    margin: 2px;
    padding: 2px 8px;
    border-radius: 10000px;
    text-align: center;
    cursor: pointer;
}



.gen-ai-answering-library-collection-item {
    padding: 16px 5px;
    /* padding-top: 16px;
    padding-bottom: 16px; */
    border-bottom: 1px solid #e5e7eb;
}

.gen-ai-answering-library-collection-item-title-link {
    color: #13343b !important;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 40px;
}

.gen-ai-answering-library-collection-item-title {
    text-align: left;
}

.gen-ai-answering-library-collection-item-time-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-library-collection-item-count {
    display: flex;
    flex-direction: row;
}

.gen-ai-answering-library-thread-collection-item-title-link {
    color: #13343b !important;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 20px;
}

.gen-ai-answering-library-thread-collections-item-contextMenu {
    padding: 4px;
    border: 1px solid #000000;
    box-shadow: 0 3px 6px #00000029;
    text-align: left;
    background: #ffffff;

}

.gen-ai-answering-library-thread-item-contextMenu-collections-title {
    padding: 2px;
    border-bottom: 1px solid #e5e7eb;
    font-weight: 700;
}

.gen-ai-answering-library-thread-item-contextMenu-collections-item-title {
    padding: 2px;
    text-align: left;
    max-width: 300px;
}

.gen-ai-answering-collection-thread-main {
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.gen-ai-answering-collection-threads-title-wrapper {
    padding: 15px 10px;
}

.gen-ai-answering-collection-threads-title {
    color: #13343b;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    border-bottom: 1px solid #e5e7eb;
    padding: 15px 10px;
}

.gen-ai-answering-collection-threads-header {
    display: flex;
    padding: 10px 5px 10px 25px;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    border-color: hsla(60, 11%, 83%, .5);
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-search-main-header-collection-svg {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gen-ai-answering-collection-threads-askquestion-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 10px 0px;
}

.gen-ai-answering-library-threads-button-deleteall {
    background: none;
}

.gen-ai-answering-result-main-answer-control {

    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-result-main-answer-control-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.gen-ai-answering-result-main-answer-control-edit,
.gen-ai-answering-result-main-answer-control-rewrite {
    background: none;
    padding: 4px 5px;
}

.gen-ai-answering-result-main-answer-control-edit:hover,
.gen-ai-answering-result-main-answer-control-rewrite:hover {
    background: #ededed;
    border-radius: 100000px;
}

.gen-ai-answering-result-question-control-section {
    padding: 0px 5px;
}

.gen-ai-answering-graph-question-control-section {
    padding: 0px 5px;
}

.gen-ai-answering-result-update-question {
    padding: 20px;
}

.gen-ai-answering-result-update-question-textarea-section>textarea {
    width: 100%;
    font-size: 14px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #000000;
    font-weight: 600;
    padding: 8px;
    height: 80px;
}

.gen-ai-answering-result-update-question-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gen-ai-answering-result-update-question-control>button {
    margin: 0 10px;
    border-radius: 9999px;
    width: 70px;
}

.gen-ai-answering-result-update-question-save {
    background-color: #033776;
    color: #ffffff;
}

.gen-ai-answering-graph-update-question {
    padding: 5px 20px 5px 20px;
}

.gen-ai-answering-graph-update-question-textarea-section>textarea {
    width: 100%;
    font-size: 14px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #000000;
    font-weight: 600;
    padding: 8px;
    height: 80px;
}

.gen-ai-answering-graph-update-question-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gen-ai-answering-graph-update-question-control>button {
    margin: 0 10px;
    border-radius: 9999px;
    width: 70px;
}

.gen-ai-answering-graph-update-question-save {
    background-color: #033776;
    color: #ffffff;
}

.gen-ai-answering-menuitem-thread-history-item-thread {
    margin-left: -25px;
    margin-top: 2px;
}

.gen-ai-answering-menuitem-thread-history-item-delete {
    cursor: pointer;
    margin-top: 3px;
}

.gen-ai-answering-library-header-input-box-wrapper {
    position: relative;
    display: inline-block;
    width: 500px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
}

.gen-ai-answering-library-header-search-box {
    width: 100%;
    height: 35px;
    border: 2px solid #d8d8d8 !important;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 15px;
    float: left;
    position: relative;
    color: #333;
    /* box-shadow: 0px 3px 6px #00000029; */
    opacity: 1;
    /* border-radius: 4px; */
    border-radius: 30px;
    padding: 1% 12% 1% 2%;
    letter-spacing: 0.02px;
    /* border: none; */
    /* margin-top: 5px; */
    padding-right: 50px;
}

.gen-ai-answering-library-header-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    width: 70px;
    background-color: transparent;
    padding: 0;
    height: 35px;
}

.gen-ai-answering-library-header-input-search-cancel-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin: 0 6%;
}

.gen-ai-answering-library-header-search-icon-wrapper-webView {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    /* border-radius: 4px; */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    opacity: 1;
    padding: 5%;
    height: 100%;
    margin-left: 10%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #ffffff;
}

.gen-ai-answering-library-header-magnifyingGlass-mobileView {
    width: 65%;
}

.gen-ai-answering-collection-threads-header-controle {
    display: flex;
    flex-direction: row;
    align-items: center;
}



.reset-select-document-type-filter-wrapper {
    align-items: center;
    /* margin-top: 1rem; */
    margin-bottom: 0;
    border: 1px solid #0074B0;
    border-radius: 7px;
    padding: 0rem 0.5rem;
    color: #0074B0;
    font-size: 15px;
    background-color: #fff;
    /* margin-left:115px; */
}

.document-type-filter-modal-wrapper {
    background-color: white;
    border: 1px solid #dcdcdc;
    font-family: 'J&J CircularTT-Medium';
    /* width: 700px; */
    width: 400px;
    margin-top: -42px;
}

.document-type-filter-modal-wrapper-publication-filter {
    /* width : 700px; */
    width: 560px;
}

.document-type-filter-modal-wrapper .document-type-filter-category {
    background-color: #fff;
    color: #0074B0;
    height: 42px;
    padding: 20px 0px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

.document-type-filter-category-text {
    /* max-width: 400px; */
    max-width: 350px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    display: inline-block;
}

.document-type-filter-times {
    display: flex;
    flex-direction: row-reverse;
}

.document-type-filter-times button {
    cursor: pointer;
    border: none;
    background: none;
}

.document-type-filter-modal-wrapper .document-type-filter-icon-times {
    font-size: 15px;
}

.document-type-filter-modal-wrapper .document-type-filter-modal-body {
    padding: 5px 0px 25px 20px;
}

.document-type-filter-modal-wrapper .document-type-filter-modal-body-inner-wrapper {
    max-height: 300px;
    overflow-y: auto;
    min-height: 200px;

}

.document-type-filter-modal-wrapper .apply-filter {
    border: 1px solid #dadada;
    border-radius: 7px;
    padding: 5px 5px;
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
    background: #FF8000;
    transition: 0.5s;
}

.apply-filter:disabled {
    cursor: not-allowed;
}

.document-type-filter-modal-wrapper .apply-filter:hover:enabled {
    background: #fff;
    color: #FF8000;
    border: 1px solid #FF8000;
}

/* .document-type-filter-modal-wrapper .apply-filter:disabled{
    border: 1px solid #dadada;
    color:#888B8D;
    background: #fff;
  } */
.document-type-filter-modal-wrapper .document-type-filter-popup-item {
    font-size: 13px;
    font-size: 13px;
    /* border-left: 1px solid #C5C5C5;
    display: flex; */
    margin: 0 0 0 2px;
    flex-direction: column;
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-button {
    display: flex;
    align-items: flex-start;
    /*  border-left: 1px solid #C5C5C5; */
    /* height: 25px; */
    height: 30px;
    padding-top: 10px;
}

.document-type-filter-modal-wrapper .document-type-filter-popup-subitem {
    margin-left: 15px;
    /* border-left: 1px solid #C5C5C5; */
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-caret {
    margin-top: 2px;
    padding: 5px 5px 5px 0;
    border: none;
    background: #fff;
    /* margin-left: -9px; */
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-label {
    display: flex;
    padding: 5px;
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-label input {
    margin-top: 3px;
    margin-right: 7px;
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-caret.hide-caret {
    visibility: hidden;
    pointer-events: none;
    /* margin-left: -9px; */
}

.document-type-filter-modal-wrapper .document-type-filter-popup-item-label input[type="checkbox"] {
    cursor: pointer;
}

.filterSubPopUp1 {
    position: absolute;
    /* left: 100%;
    top: 0px; */

}

.document-type-filter-sub-category-wrapper {
    line-height: 15px;
    width: 100%;
}

.gen-ai-answering-result-combined-summary-wrapper-span {
    text-align: left;
    background-color: #f3f3ee;
    padding: 10px;
}

.gen-ai-answering-result-main-answer-display-string-multi-files-document-title {
    padding: 10px 0px;
}

div.gen-ai-answering-result-combined-summary-wrapper-span>span>ul {
    margin: 0px !important;
}

.gen-ai-answering-result-combined-summary-wrapper {
    padding-right: 5px;
}

.gen-ai-answering-main-search-question-txt-btn-section>textarea {
    margin: 0;
    height: 100%;
    font-size: 17px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #000000;
    width: calc(100% - 32px);
    padding: 8px;
    color: #000000;
    /* font-weight: 700; */
    border: none;
}

.gen-ai-answering-main-search-question {
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #A9A9A9;
    background-color: #ffffff;
}

.gen-ai-answering-main-search-question {
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-main-search-action-section {
    background: #fff;
    padding: 0px 2px 0px 2px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d9d9d0;
    margin: 0px 20px 10px 20px;
}

.gen-ai-answering-main-search-action-section-listfile:hover,
.gen-ai-answering-main-action-section-listfile:hover {
    background-color: #e8e8e3;
    cursor: pointer;
}

.gen-ai-answering-main-search-action-section-document-filters {
    padding: 5px 10px;
    background-color: #2a498a;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 10px;
    width: 150px;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
}

.gen-ai-answering-main-search-action-section-listfile,
.gen-ai-answering-main-action-section-listfile {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D9D9D9;
    margin-top: 10px;
    ;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gen-ai-answering-main-action-section-listfile {
    margin-bottom: 5px;
}

.gen-ai-answering-main-search-action-section-listfile>button {
    color: #ffffff;
}

.gen-ai-answering-main-search-question-txt-btn-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.gen-ai-answering-main-search-action-document-filters-popup-model-body {
    height: 45vh;
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-main-search-action-document-filters-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 99%;
}

.gen-ai-answering-result-display-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}

.gen-ai-answering-result-display-filters-lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.gen-ai-answering-result-display-filters-item {
    margin: 0 12px;
    color: #0698e0;
}

.z-index-gen-ai-answering-search-spinner-popup {
    z-index: 1701;
}

.gen-ai-answering-search-spinner-popup {
    z-index: 1704;
}

.gen-ai-answering-clear-all-button {
    align-items: center;
    background-color: #fff;
    border: 2px solid #033776;
    border-radius: 5px;
    color: #033776;
    font-size: 15px;
    margin-bottom: 0;
    padding: 0 .5rem;
}

.gen-ai-answering-filter-display-remove-button-wrapper {
    display: flex;
    padding: 10px 0;
}

.gen-ai-answering-main-search-action-document-filters-body>.gen-ai-answering-filter-display-remove-button-wrapper {
    display: flex;
    padding: 10px 0;
    flex-direction: row;
    width: 100%;
    padding-left: 12px;
}


.gen-ai-answering-result-main-answer-control-reaction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    padding-right: 8px;
    width: 80px;
}


.gen-ai-answering-result-main-answer-control-reaction>button {
    cursor: pointer;
    outline: 0;
    color: #AAA;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #ededed;
    margin-left: 2px;
}


.gen-ai-answering-result-main-answer-control-reaction>button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.btn-like-green {
    color: green !important;
}

.btn-unlike-red {
    color: red !important;
}

.gen-ai-answering-result-main-answer-control-report-ans-textbox-submit,
.gen-ai-answering-result-main-answer-control-report-ans {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.gen-ai-answering-result-main-answer-control-report-ans-processing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 490px;
}

.gen-ai-answering-result-main-answer-control-report-ans-button-report-answer,
.gen-ai-answering-result-main-answer-control-report-ans-button-submit {
    height: 31px;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D9D9D9;
}

.gen-ai-answering-result-main-answer-control-report-ans-button-report-answer {
    width: 110px;
}

.gen-ai-answering-result-main-answer-control-report-ans-button-submit {
    width: 70px;
}

.gen-ai-answering-result-main-answer-control-report-ans-textbox>input {
    height: 31px;
    font-size: 12px;
    font-weight: 600;
    width: 380px;
    margin-right: 12px;
}

.gen-ai-answering-result-main-answer-control-report-ans-close-button {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
}

.gen-ai-answering-result-main-answer-control-report-ans-close-button>img {
    cursor: pointer;
    width: 12px;
}

/* .gen-ai-answering-result-main-answer-control-report-ans{
    width: 558px;
} */
.gen-ai-answering-result-main-answer-control-report-message {
    color: green;
    font-size: 13px;
    font-weight: 700;
}

.gen-ai-answering-main-action-section-upload {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.gen-ai-answering-result-main-answer-control-copy {
    cursor: pointer;
    outline: 0;
    color: #000000;
    display: flex;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 7px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background: none;
    margin-left: 5px;
    border-radius: 9999px;
}

.gen-ai-answering-result-main-answer-control-copied {
    cursor: pointer;
    outline: 0;
    color: #000000;
    display: flex;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-left: 5px;
    background: none;
    padding: 5px;
    border-radius: 9999px;
}

.gen-ai-answering-result-main-answer-control-copy:hover {
    background-color: #ededed;
    border-radius: 9999px;
}

.gen-ai-answering-result-main-answer-control-reaction>button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.gen-ai-answering-main-footer {
    padding: 10px 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.gen-ai-answering-main-footer-title {
    background-color: #E4F3FF;
    width: 100%;
    padding: 3px 2px;
    border-radius: 5px;
    font-weight: 700;
}

.gen-ai-answering-main-footer-content {
    text-align: left;
    padding: 10px 20px;
}

.gen-ai-answering-main-footer-content>p,
.gen-ai-answering-main-footer-content>li,
.gen-ai-answering-main-footer-content-link {
    margin-bottom: 2px;
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
    .gen-ai-answering-sidebar {
        width: 175px !important;
        min-width: 175px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 140px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 185px);
    }

    .gen-ai-answering-main-footer-title {
        font-size: 13px;
    }

    .gen-ai-answering-main-footer {
        padding: 10px 50px;

    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 12px;
    }

    .authoring-model-body-content-link {
        font-size: 12px;
    }

    div.gen-ai-answering-result-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 170px;
    }

    div.gen-ai-answering-graph-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 170px;
    }
}


@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .gen-ai-answering-sidebar {
        width: 185px !important;
        min-width: 185px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 150px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 195px);
    }

    .gen-ai-answering-main-footer-title {
        font-size: 13px;
    }

    .gen-ai-answering-main-footer {
        padding: 10px 50px;

    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 12px;
    }

    .authoring-model-body-content-link {
        font-size: 12px;
    }

    div.gen-ai-answering-result-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 190px;
    }

    div.gen-ai-answering-graph-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 190px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .gen-ai-answering-sidebar {
        width: 195px !important;
        min-width: 195px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 160px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 205px);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 50px;

    }

    .gen-ai-answering-main-footer-title {
        font-size: 13px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 12px;
    }

    .authoring-model-body-content-link {
        font-size: 12px;
    }

    div.gen-ai-answering-result-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 210px;
    }

    div.gen-ai-answering-graph-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 210px;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .gen-ai-answering-sidebar {
        width: 205px !important;
        min-width: 205px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 170px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 215px);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 50px;

    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 13px;
    }

    .authoring-model-body-content-link {
        font-size: 13px;
    }

    div.gen-ai-answering-result-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 220px;
    }

    div.gen-ai-answering-graph-update-question>div.gen-ai-answering-main-focus-wrapper>div.gen-ai-answering-main-focus-item {
        width: 220px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .gen-ai-answering-sidebar {
        width: 215px !important;
        min-width: 215px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 180px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 225px);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 50px;

    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 13px;
    }

    .authoring-model-body-content-link {
        font-size: 13px;
    }

}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .gen-ai-answering-sidebar {
        width: 225px !important;
        min-width: 225px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 190px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 235px);
    }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .gen-ai-answering-sidebar {
        width: 250px !important;
        min-width: 250px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 215px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 260px);
    }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
    .gen-ai-answering-sidebar {
        width: 275px !important;
        min-width: 275px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 240px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 285px);
    }
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
    .gen-ai-answering-sidebar {
        width: 300px !important;
        min-width: 300px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 265px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 310px);
    }
}

@media screen and (min-width: 1901px) and (max-width: 2000px) {
    .gen-ai-answering-sidebar {
        width: 325px !important;
        min-width: 325px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 290px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 335px);
    }
}

@media screen and (min-width: 2001px) {
    .gen-ai-answering-sidebar {
        width: 325px !important;
        min-width: 325px !important;
    }

    .gen-ai-answering-menuitem-thread-history {
        width: 290px;
    }

    .stickyHeaderExpand {
        width: calc(100% - 335px);
    }
}

.gen-ai-answering-main-footer-content>p,
.gen-ai-answering-main-footer-content>li,
.gen-ai-answering-main-footer-content-link {
    font-size: 12px;
}

.gen-ai-answering-sidebar.ps-collapsed {
    width: 80px !important;
    min-width: 80px !important;
}

.gen-ai-answering-window-preview-header {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
}

.gen-ai-document-window-preview-expand {
    width: calc(100% - 320px);
}

.gen-ai-document-window-preview-collapsed {
    width: calc(100% - 70px);
}

.window-preview-collapsed {

    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    overflow: hidden;
    transform: rotate(180deg);
}

.window-preview-Expand {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    overflow: hidden;
}

.window-preview-open-close {
    width: 30px;
    vertical-align: middle;
    fill: currentColor;
    overflow: hidden;
}

.gen-ai-answering-window-preview-left {
    display: flex;
    align-items: center;
}

.gen-ai-answering-window-preview-sidebar {
    width: 300px !important;
    min-width: 300px !important;
}

.gen-ai-answering-window-preview-sidebar.ps-collapsed {
    width: 50px !important;
    min-width: 50px !important;
}

.gen-ai-answering-window-preview-sidebar>.css-dip3t8 {
    background-color: #ffffff;
}

.gen-ai-answering-window-preview-right {
    width: calc(100% - 42px);
}

.gen-ai-answering-preview-window-source-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    min-height: 40px;
    font-size: 14px;
    color: #0074b0;
    text-decoration: none;
    word-break: break-word;
}


.gen-ai-answering-preview-window-source-item-title:hover {
    text-decoration: underline;
}

.gen-ai-answering-preview-window-source-item-citations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.gen-ai-answering-preview-window-source-main {
    text-align: left;
}

.gen-ai-answering-preview-window-source-item-citation-number {
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-preview-window-source-item-citation-number>label {
    margin: 3px 0px;
}

.gen-ai-answering-window-preview-sidebar.css-1wvake5 {
    border-right-width: 1px;
    border-right-style: none;
    border-left-width: 1px;
    border-left-style: solid;
}

.gen-ai-answering-preview-window-source-citation-item {
    display: flex;
}

.gen-ai-answering-result-source-item-protocol {
    font-size: 12px;
}

.gen-ai-answering-result-source-item-protocol-recommendation,
.gen-ai-answering-result-source-item-protocol-preview-sidebar,
.gen-ai-answering-result-source-item-docType {
    font-size: 14px;
}

.gen-ai-answering-result-source-margin {
    margin: 1px 0;
}

.gen-ai-answering-main-action-section-comprehensive {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding: 5px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.gen-ai-answering-main-action-section-comprehensive-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.gen-ai-answering-main-action-section-comprehensive-search>label {
    margin-top: 4px;
}

.gen-ai-answering-library-thread-item-time-ago {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.gen-ai-graph-library-thread-item-time-ago {
    font-size: 14px;
    margin: 0 10px;
    font-weight: 600;
}

.gen-ai-answering-follow-up-footer-content {
    padding: 2px 3px;
    font-size: 13px;
    text-align: left;
}

.gen-ai-answering-follow-up-footer-content>p {
    margin-bottom: 0;
}

div.gen-ai-answering-follow-up-footer-content>p>a {
    font-size: 13px;
}

.gen-ai-answering-result-main-more-information-answer {
    height: 31px;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D9D9D9;
    width: 200px;
    margin-bottom: 10px;
}

.gen-ai-answering-result-main-answer-control-hide-show {
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D9D9D9;
    padding: 5px;
    width: 80px;
}

.alertmessage-file-label {
    margin-bottom: 3px;
    font-weight: 600;
}

.gen-ai-answering-main-footer-content {
    margin-bottom: 5px;
}

@media screen and (min-height: 440px) and (max-height: 494px) {
    .gen-ai-answering-main-ask-question {
        height: 100px;
    }

    .gen-ai-answering-main-close {
        padding: 5px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 55%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 20px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link,
    .gen-ai-answering-main-footer-title {
        font-size: 10px;
    }

    .gen-ai-answering-main-footer-content {
        text-align: left;
        padding: 3px 20px;
    }

    .gen-ai-answering-main-img {
        margin-bottom: 15px;
        margin-top: -50px;
    }

    .gen-ai-answering-main-focus-item,
    .gen-ai-answering-filter-display-remove-button-wrapper>button {
        font-size: 10px;
    }

    div.gen-ai-answering-filter-display-remove-button-wrapper>button>img {
        width: 9px;
    }

    .gen-ai-answering-main-action-section-file {
        font-size: 10px;
    }

    .gen-ai-answering-main-ask-question>textarea {
        font-size: 14px;
    }

    .gen-ai-answering-main-focus-wrapper {
        padding-top: 5px;
        gap: 5px;
    }

    .gen-ai-answering-filter-display-remove-button-wrapper {
        padding: 5px 0;
    }

    div.z-index-authoring>.modal-dialog {
        margin-top: 54px;
    }
}


@media screen and (min-height: 495px) and (max-height: 550px) {
    .gen-ai-answering-main-ask-question {
        height: 115px;
    }

    .gen-ai-answering-main-close {
        padding: 5px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 50%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 20px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link,
    .gen-ai-answering-main-footer-title {
        font-size: 10px;
    }

    .gen-ai-answering-main-footer-content {
        text-align: left;
        padding: 3px 20px;
    }
}

@media screen and (min-height: 551px) and (max-height: 600px) {
    .gen-ai-answering-main-ask-question {
        height: 120px;
    }

    .gen-ai-answering-main-footer-content {
        text-align: left;
        padding: 3px 20px;
    }

    .gen-ai-answering-main-close {
        padding: 5px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 60%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 20px;
    }

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link,
    .gen-ai-answering-main-footer-title {
        font-size: 10px;
    }

    div.z-index-authoring>.modal-dialog {
        margin-top: 50px;
    }

}

@media screen and (min-height: 601px) and (max-height: 650px) {
    .gen-ai-answering-main-section {
        height: calc(100vh - 55%);
    }

    div.z-index-authoring>.modal-dialog {
        margin-top: 50px;
    }
}

@media screen and (min-height: 651px) and (max-height: 700px) {
    .gen-ai-answering-main-section {
        height: calc(100vh - 62%);
    }
}

@media screen and (min-height: 701px) and (max-height: 750px) {
    .gen-ai-answering-main-section {
        height: calc(100vh - 75%);
    }
}

@media screen and (min-height: 751px) and (max-height: 800px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 13px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 78%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 801px) and (max-height: 850px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 78%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 851px) and (max-height: 900px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 85%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 901px) and (max-height: 950px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 90%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 951px) and (max-height: 1000px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 95%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 951px) {

    .gen-ai-answering-main-footer-content>p,
    .gen-ai-answering-main-footer-content>li,
    .gen-ai-answering-main-footer-content-link {
        font-size: 14px;
    }

    .gen-ai-answering-main-footer-title {
        font-size: 14px;
    }

    .gen-ai-answering-main-section {
        height: calc(100vh - 95%);
    }

    .gen-ai-answering-main-footer {
        padding: 10px 25px;
    }
}

@media screen and (min-height: 701px) {
    .gen-ai-answering-main-section {
        height: calc(100vh - 60vh);
    }
}

.gen-ai-answering-Authoring {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D9D9D9;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-left: 5px;
    height: 30px;
}

.gen-ai-answering-Authoring:hover {
    background-color: #e8e8e3;
    cursor: pointer;
}

.gen-ai-answering-authoring-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-authoring-left,
.gen-ai-answering-authoring-right {
    padding: 5px;
    /* width: 50%; */
}

.gen-ai-answering-authoring-right-collapsed {
    width: calc(100% - 28px);
}

.gen-ai-answering-authoring-right-expand {
    width: 50%;
}

.gen-ai-answering-authoring-top {
    text-align: center;
    width: 100%;
    margin-bottom: 2px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.gen-ai-answering-authoring-img-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-answering-authoring-img-wrapper>.gen-ai-answering-authoring-img {
    width: 300px
}

.gen-ai-answering-authoring-sidebar.ps-collapsed {
    width: 28px !important;
    min-width: 28px !important;
}

.gen-ai-answering-authoring-sidebar.css-1wvake5 {
    width: 50%;
}

.gen-ai-answering-authoring-sidebar-expand.css-1wvake5 {
    height: 100%;
}

.gen-ai-answering-authoring-menuitem-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.gen-ai-answering-authoring-menuitem-arrow-left-to-line {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-authoring-bottom {
    height: calc(100vh - 58px);
    display: flex;
    flex-direction: row;
}

.gen-ai-answering-authoring-menuitem-arrow-expand-left-to-line {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.gen-ai-document-answering-authoring-window-preview-panel {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

div.gen-ai-answering-authoring-bottom>.gen-ai-answering-authoring-sidebar.css-1wvake5>.ps-sidebar-container,
div.gen-ai-answering-authoring-bottom>.gen-ai-answering-authoring-sidebar.css-1wvake5>.ps-sidebar-container>nav,
div.gen-ai-answering-authoring-bottom>.gen-ai-answering-authoring-sidebar.css-1wvake5>.ps-sidebar-container>nav>ul,
div.gen-ai-answering-authoring-bottom>.gen-ai-answering-authoring-sidebar.css-1wvake5>.ps-sidebar-container>nav>ul>.gen-ai-answering-authoring-menuitem-header {
    height: 100%;
}

.gen-ai-answering-authoring-expand-icon {
    margin-bottom: 5px;
    margin-top: 5px;
}

.gen-ai-answering-authoring-collapsed-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.gen-ai-answering-authoring-expand-icon {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
}

.gen-ai-answering-authoring-dropdown {
    padding-right: 5px;
}

.gen-ai-answering-authoring-dropdown>select {
    width: 250px;
}

.gen-ai-answering-authoring-collapsed-icon {
    padding-left: 5px;
}

.answering-authoring-summery-result-wrapper-gpt {
    height: 54%;
}

.answering-authoring-file-dropdown {
    border-radius: 3px;
    padding: 3px;
    font-size: 14px;
    /* margin: 0px 0px 5px 0px; */
    /* border: none; */
}

.answering-authoring-result-label-gpt,
.answering-authoring-source-label-gpt,
.answering-authoring-command-label-gpt,
.answering-authoring-gpt-citation-label {
    color: #203864;
    font-size: 15px;
    font-weight: 600;
}

.answering-authoring-result-label-gpt,
.answering-authoring-source-label-gpt,
.answering-authoring-command-label-gpt,
.answering-authoring-gpt-citation-label {
    display: flex;
    justify-content: space-between;
}

.answering-authoring-result-panel-gpt {
    height: calc(100% - 25px);
    border: 1px solid #bacef3;
    border-radius: 5px;
}

.answering-authoring-summery-comment-send-save-notebook-wrapper-gpt {
    height: 28%;
    margin-bottom: 2px;
}

.answering-authoring-command-wrapper-panel-gpt {
    background-color: #E7E6E6;
    padding: 1%;
    height: calc(100% - 25px);
    border-radius: 5px;
    border: 1px solid #bacef3;
}

.answering-authoring-command-dropdown-gpt {
    width: 100%;
    border-radius: 3px;
    padding: 3px;
    font-size: 14px;
    margin: 0px 0px 5px 0px;
    border: none;
}

.answering-authoring-summery-comment-send-gpt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: calc(100% - 28px);
}

.answering-authoring-comment-panel-gpt {
    width: 73%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.answering-authoring-comment-panel-gpt>textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 14px;
    resize: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
}

.answering-authoring-send-panel-gpt {
    width: 27%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.answering-authoring-send-panel-gpt>button {
    width: 90%;
    padding: 3px;
}

.answering-authoring-send-panel-gpt>button {
    width: 150px;
    height: 24px;
    font-weight: 600;
}

.answering-authoring-summery-citation-wrapper-gpt {
    height: 15%;
}

.answering-authoring-gpt-summery-citation-send>textarea {
    width: 100%;
    margin: 0;
    height: 100%;
    font-size: 14px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #bacef3;
    font-weight: 600;
}

.answering-authoring-gpt-summery-citation-send {
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #bacef3;
}

.answering-authoring-gpt-summery-citation-send>textarea {
    border: none
}

.answering-authoring-gpt-summery-citation-send {
    height: calc(100% - 25px);
    display: flex;
    flex-direction: column;
}

.answering-authoring-summery-gpt-citation-link {
    font-weight: 600;
    font-size: 14px;
}

.answering-authoring-summery-citation-seprater {
    border-left: 1px solid #203864;
    margin: 0px 5px;
}

/*.graph-sub-wrapper-menu {
    list-style: none;
    position: absolute;
    z-index: 100;
    text-align: left;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.5% 0 1.2% 0;
    border: 1px solid #1DA1E5;
    width: 498px;
    height: 130px;
    -webkit-transition: max-height 5s; 
    -moz-transition: max-height 5s; 
    -ms-transition: max-height 5s; 
    -o-transition: max-height 5s; 
    transition: max-height 5s;
    overflow-y: scroll;
}

.graph-sub-wrapper-menu li {
    padding-left: 1%;
    margin-bottom: 8px;
}

.graph-sub-wrapper-menu li:hover {
    background-color:#c5e0ee;
}

.menu-image{
    margin: 7px;
    width: 40px;
    height: 40px;
}
.select-graph-wrapper{
    background : white; 
    width: 495px ;
    margin-bottom: 3px;
    display:flex; 
    justify-content:space-between;
    margin: 1px ;
    padding: 1px;
}*/

.gen-ai-answering-prompt-template-shortcut {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    padding-top: 7px;
}

.gen-ai-answering-prompt-template-shortcut-item {
    background-color: #F0F3F7;
    border-radius: 5px;
    border: 1px solid #203864;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 10px;
    margin: 0 10px;
}

.gen-ai-answering-prompt-template-shortcut-label {
    font-weight: 600;
    color: #033776;
    font-size: 14px;
    text-decoration: none;
    margin: 0 10px;
}

.gen-ai-answering-main-action-section-send-wrrap,
.gen-ai-answering-main-action-search-section-send-wrrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gen-ai-answering-main-action-section-send-wrrap>.gen-ai-answering-prompt-template-shortcut-label,
.gen-ai-answering-main-action-search-section-send-wrrap>.gen-ai-answering-prompt-template-shortcut-label {
    margin-right: 5px;
}

.gen-ai-answering-main-action-search-section-send-wrrap>.gen-ai-answering-prompt-template-shortcut-label {
    margin-top: 10px;
}


.gen-ai-answering-main-action-section-graph {
    padding: 5px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: solid 1px #ffffff;
}

.gen-ai-answering-main-action-section-graph:hover {
    background-color: #e8e8e3;
    color: #13343b;
    border-radius: 100px;
    cursor: pointer;
    border: solid 1px #ffffff;
}

.gen-ai-answering-main-action-section-graph-active {
    background-color: #2a498a;
    color: #ffffff;
    border-radius: 100px;
    cursor: pointer;
    border: solid 1px #000000;
    padding: 5px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.gen-ai-answering-main-action-section-graph-svg {
    border: solid 1px #2a498a;
    border-radius: 100px;
}

.gen-ai-answering-main-graph-wrapper,
.gen-ai-answering-result-graph-wrapper {
    position: absolute;
    display: flex;
    margin-top: 35px;
    z-index: 10000;
    flex-direction: column;
    justify-content: space-between;
    bottom: inherit;
    width: 180px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    height: 180px;
}

.gen-ai-answering-result-graph-wrapper {
    top: -135px;
}

.gen-ai-answering-main-graph-open {
    display: block;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.gen-ai-answering-main-graph-close {

    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.gen-ai-answering-main-graph-items {
    overflow-y: auto;
    height: 160px;
    width: 100%;
}

.gen-ai-answering-main-graph-item-ul {
    list-style: none;
    padding-left: 0px;
}

.gen-ai-answering-main-graph-item-image {
    margin: 5px;
    width: 30px;
    height: 30px;
}

.gen-ai-answering-main-graph-select {
    background: white;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;
    margin: 1px;
    padding: 1px;
    width: 180px;
}

.gen-ai-answering-main-graph-item-ul>li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.gen-ai-answering-main-graph-custome-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 5px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gen-ai-answering-graph-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
}

.gen-ai-answering-graph-prompt-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-weight: 700;
    padding: 20px 20px 5px 20px;
    height: 57px;
}

.gen-ai-answering-graph-top,
.gen-ai-answering-graph-bottom {
    width: 100%;
}

.gen-ai-answering-graph-prompt-left {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.gen-ai-answering-graph-bottom {
    display: flex;
    flex-direction: column;
}

.gen-ai-answering-graph-no-graph {
    min-height: 300px;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gen-ai-answering-graph-top-controle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 5px 20px;
}

.gen-ai-answering-graph-top-controle-right-milestone-section>button:hover {
    background: #ededed;
    border-radius: 100000px;
}

.gen-ai-graph-add-milestone-container,
.gen-ai-graph-view-milestone-container {
    display: flex;
    flex-flow: column;
    border: 1px solid #d3d3d3;
    padding: 10px 15px;
    z-index: 1001;
    right: 0;
    background: #fff;
    height: 99vh;
    overflow-y: auto;
    top: 65px;
    flex-direction: column;
    width: 100%;
}

.gen-ai-graph-add-milestone-container-top,
.gen-ai-graph-view-milestone-container-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.gen-ai-graph-add-milestone-container-top-title,
.gen-ai-graph-view-milestone-container-top-title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.gen-ai-graph-add-milestone-container-bottom {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gen-ai-graph-view-milestone-container-bottom {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 450px;
}

.gen-ai-graph-add-milestone-container-bottom-right {
    width: 30%;
    padding: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}



.gen-ai-graph-view-milestone-container-bottom-right-controle-label,
.gen-ai-graph-view-milestone-container-bottom-right-controle-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.gen-ai-graph-add-milestone-container-bottom-left {
    width: 70%;
    padding: 5px;
    height: 100%;
    border: solid 1px #000000;
    border-radius: 5px;
}

.gen-ai-graph-view-milestone-container-bottom-center {
    width: 70%;
    padding: 5px;
    height: 100%;
    border: solid 1px #000000;
    border-radius: 5px;
}

.gen-ai-graph-view-milestone-container-bottom-center>iframe {
    height: calc(100% - 73px);
    width: calc(100% - 10px);
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch
}

.gen-ai-graph-view-milestone-container-bottom-left {
    width: 15%;
    padding: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

.gen-ai-graph-add-milestone-container-bottom-header,
.gen-ai-graph-view-milestone-container-bottom-header {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gen-ai-graph-add-milestone-container-bottom-footer {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gen-ai-graph-add-milestone-container-bottom-left>iframe {
    height: calc(100% - 73px);
    width: calc(100% - 10px);
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch
}

.gen-ai-graph-view-milestone-container-bottom-right-controle {
    width: 100%;
}

.gen-ai-graph-add-milestone-container-bottom-right-controle,
.gen-ai-graph-view-milestone-container-bottom-right-controle {
    width: 100%;
}

.gen-ai-graph-view-milestone-container-bottom-right-controle-text {
    width: 100%;
}

.gen-ai-graph-add-milestone-container-bottom-right-controle-text {
    width: 100%;
}

.gen-ai-graph-add-milestone-container-bottom-right-controle-text>input {
    width: 90%;
}

.gen-ai-graph-add-milestone-container-bottom-footer-save {
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;
    opacity: 1;
    padding: 4px 5px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 24px;
    color: #FFFFFF;
    font-size: 14px;
}

.GenAI-text-area {
    width: 100%;
    margin: 1px;
    height: 90px;
    font-size: 14px;
    resize: none;
    border: none;
    /*padding-right: 20px;*/
    padding: 2px 25px 2px 2px;

}

.GenAI-text-area-crossIcon {
    /* position: absolute;
        margin-left: 656px; */
    margin-right: 5px;
    margin-bottom: 5px;
}

.genAI-top-editor {
    /*width: 690px;*/
    border-width: 1px;
    border-radius: 5px;
    border-color: #bacef3;
    border-style: solid;
    position: relative;
}


.GenAI-text-area-crossIcon img:hover {
    cursor: pointer;
}

.answering-authoring-source-label-gpt span:hover {
    cursor: pointer;
}

.gen-ai-graph-view-milestone-container-bottom-right-top,
.gen-ai-graph-view-milestone-container-bottom-right-bottom {
    height: calc(50% - 23px);
    border-left: dotted 1.5px;
}

.gen-ai-graph-view-milestone-container-bottom-right {
    width: 15%;
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.gen-ai-graph-view-milestone-container-bottom-right-controle-revert-to-milestone {
    display: flex;
    color: #2a498a;
    justify-content: center;
    cursor: pointer;
}

.gen-ai-graph-view-milestone-container-bottom-question-message-show-more-less {
    color: #0074B0;
    font-size: "14px";
    font-weight: "bold";
    text-align: center;
}

.gen-ai-graph-view-milestone-container-bottom-question-message-show-more-less:hover {
    cursor: pointer;
    text-decoration: underline;
}

.gen-ai-graph-view-milestone-container-bottom-empty {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: "14px";
    font-weight: "bold";
}

.answering-authoring-source-command-citation {
    /* height: 43%; */
    /* max-height: 225px; */
    max-height: 42.5%;
    overflow-y: auto;
    overflow-x: hidden;
}

.gen-ai-answering-main-action-section-authoring {
    margin-top: 10px;
}

.gen-ai-search-multi-question-loader {
    /* border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #A9A9A9; */
    padding: 20px;
}

.gen-ai-answering-image-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    /* height: 100%; */
    height: calc(100% - 50px);
}

.gen-ai-answering-image-top,
.gen-ai-answering-image-bottom {
    width: 100%;
}

.gen-ai-answering-image-top {
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.gen-ai-answering-image-bottom {
    height: 60%;
    overflow-y: auto;
    max-height: 60%;
}

.gen-ai-answering-image-no-image {
    font-weight: 600;
}

.gen-ai-answering-image-no-image>p {
    display: flex;
    align-items: center;
}
.gen-ai-answering-image-top-img-section{
    height: 100%;
}
.gen-ai-answering-image-top-img{
    max-height: 100%;
}
