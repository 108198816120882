.excel-data-table-wrapper {
    margin: 1% 1%;
}

.excel-data-table-wrapper tbody tr td {
    text-align: left !important;
}

.excel-data-function-button-wrapper {
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    padding: 1% 0 1% 0;
    text-align: left;
}

.table>thead {
    vertical-align: middle;
}

/* .table-responsive-min-width table {
    min-width: auto;
} */

element.style {
    width: 100%;
    background: rgb(45, 134, 181);
    height: auto;
}

.imsResultsMenuHeaderNotebook {
    height: 25px;
    font-size: 18px;
    cursor: pointer;
    padding: 1px 20px;
    color: #fff;
    background: #fa9546;
    /* line-height: 32px; */
    position: relative;
    margin-top: -15px;
    clear: both;
    /* margin-left: 1%; */
    text-align: center;
    float: left;
}

.excel-data-table-wrapper-notbook {
    display: flex;
    flex-direction: column;
}

.btnCreateDraft {
    text-align: center;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 2px 4%;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    color: #000;
    background: #fff;
    margin: 4px
}

td.react-bootstrap-table-editing-cell>textarea {
    min-height: 200px;
}

.nbv-popup-button-wrapper {
    padding: 2%;
    float: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.nbv-popup-button-wrapper>button {
    margin: 2px;
}
.nbv-popup-inputContainer-checkbox{
    margin: 5% 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 52px;
}
.gsf-popup-inputPart-checkbox {
    display: flex;
    align-items: center;
    font-size: 15px;
}
.nbv-popup-inputContainer {
    margin: 1% 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}


.nbv-notes-cell,
.nbv-notes-edit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.nbv-notes-edit>.nbv-notes-edit-cell-btn>span {
    color: #0074B0;
    text-decoration: none;
}

.nbv-notes-edit>.nbv-notes-edit-cell-btn>span:hover {
    text-decoration: underline;
    color: #0074B0;
    cursor: pointer;
}

.nbv-notes-edit>.nbv-notes-cell-txt>textarea {
    width: 100%;
    height: 300px;
    border-width: 1px;
    font-size: 14px !important;
}

.nbv-notes-cell-txt {
    width: 100%;
}

.nbv-notes-edit>.nbv-notes-edit-cell-btn>span {
    margin: 0px 5px;
}

.nbv-notes-cell-btn,
.nbv-notes-edit-cell-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.nbv-notes-cell-action-text,
.nbv-notes-cell-action-cancel {
    text-decoration: none;
    /* margin-top: 10px; */
    margin: 5px;
}

.nbv-notes-cell-action-text:hover,
.nbv-notes-cell-action-cancel:hover {
    text-decoration: underline;
}

@media screen and (min-width:600px) and (max-width:999px) {
    .excel-data-table-wrapper {
        margin: 15px 1%;
    }

    .nbv-notes-cell-btn,
    .nbv-notes-edit-cell-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width:599px) {
    .excel-data-table-wrapper {
        margin: 4% 1%;
    }

    .nbv-notes-cell-btn,
    .nbv-notes-edit-cell-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.notebook-table-checkbox-cell>table>tbody>tr>td.selection-cell {
    text-align: center !important;
}

.notebook-data-function-button-wrapper {
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    padding: 1%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    opacity: 1;
    margin: 0 0 12px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nbv-notes-cell-value {
    height: 300px;
    overflow-y: auto;
    word-break: break-word;
}

.nbv-notes-cell-value::-webkit-scrollbar {
    /* width: 5px; */
    /* width of the entire scrollbar */
}

.notebook-table-checkbox-cell>table>tbody>tr>td:nth-child(2) {
    text-align: center !important;
}

.react-bootstrap-table th.table-summarized-html-cell-header {
    width: 200px;
}

.table-message-text {
    height: 300px;
    overflow-y: auto;
    word-break: break-word;
}

.notebook-table-checkbox-cell::-webkit-scrollbar ,.table-message-text::-webkit-scrollbar {
    height: 10px !important
}

.table-responsive-min-width {
    overflow-y: auto;
    max-height:500px;
}

div.react-bootstrap-table.table-responsive.table-responsive-min-width.notebook-table-checkbox-cell>table>thead>tr>th.selection-cell-header {
    width: 15px;
    position: sticky;
    top: 0px;
    box-shadow: inset 0 2px 0 #808080, inset 0 -2px 0 #808080;
    background-color: #fff;
}

div.react-bootstrap-table.table-responsive.table-responsive-min-width.notebook-table-checkbox-cell>table>thead>tr{
    border-top: none !important;
    border-bottom: none !important;
}