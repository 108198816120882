.App {
  text-align: center;
  /*  min-width: 1366px; */
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Scroll Bar CSS Start*/
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 5px;
}

/*Scroll Bar CSS End*/
/*Disclaimer Page CSS Start*/
.disclaimerWrapper {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9d9d9d4f;
  opacity: 1;
  color: #333;
  width: 96%;
  margin: 20px auto;
  padding: 33px;
  box-sizing: border-box;
  text-align: initial;
  font-family: 'J&J CircularTT-Medium' !important;
}

.disclaimerTitle {
  padding: 0 0 10px;
  font-size: 26px;
}

.disclaimerTitleTablet {
  padding: 0 0 10px;
  font-size: 24px;
}

.disclaimerBody {
  font-size: 14px;
  line-height: 1.42857143;
}

.disclaimerBodyTablet {
  font-size: 14px;
  line-height: 1.42857143;
}

.disclaimerBtn {
  text-align: right;
  margin: 30px 0 0;
}

.disclaimerBody a {
  color: #1076bc;
  cursor: pointer
}

.disclaimerBtn input[type="submit"] {
  display: inline-block;
  background: #0072c6;
  color: #fff;
  font-size: 14px;
  line-height: 33px;
  height: 33px;
  padding: 0 12px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none !important;
  font-family: 'J&J CircularTT-Medium' !important;
}

.errorLabel {
  color: red;
}

.disclaimer-btn-mobile button {
  color: rgb(46, 176, 0);
  ;
  border: 1px solid rgb(46, 176, 0);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
}

.disclaimer-btn-mobile button:hover {
  color: rgb(255, 255, 255);
  background: rgb(46, 176, 0);
}

/*Disclaimer Page CSS End*/

/*Header & Footer CSS Start*/
.bannerImage {
  /* background: url(../assets/bannerNew.png) no-repeat 0 0; */
  /* background: url(../assets/header-bg-img.png) no-repeat 0 0; */
  background: url(../assets/banner.png) no-repeat 0 0;
  padding: 25px 0 45px;
  position: relative;
  /* background-size: 100% 100%; */
  background-size: 100%;
}

.bannerImage-recommended {
  padding-bottom: 2% !important;
}

.bannerMenuIcon {
  float: left;
  margin: -20px 4px;
  height: 46px;
  width: 46px;
  /* margin: 0 10px 0 0; */
  position: relative;
}

.bannerMenuIcon p {
  position: absolute;
  top: 48px;
  color: #7d7a7a;
  font-size: 13px;
  left: 1px;
  text-decoration: none;
  margin: 0 0 10px;
  font-family: 'J&J CircularTT-Medium';
}

.bannerMenuIcon #tooltipdis {
  font-family: 'J&J CircularTT-Medium' !important;
}

.length-info{
  color: grey;
  font-style: italic;
}

.filterMenuSearchLink {
  font-size: 16px;
  background: #5e8ebd;
  color: #fff;
  height: 42px;
  line-height: 32px;
  position: relative;
  margin-top: -32px;
  clear: both;
}

img.bannerImage {
  vertical-align: middle;
}

.headercontent {
  height: 62px;
  margin: 0 1% 0 2%;
  width: 96%;
}

.headerLogo {
  float: left;
  height: 46px;
  width: 46px;
  margin: 0 10px 0 0;
  position: relative;
  /* padding-right: 0.8%; */
}

.headerLogo p {
  position: absolute;
  top: 48px;
  color: #7d7a7a;
  font-size: 13px;
  left: 1px;
  text-decoration: none;
  margin: 0 0 10px;
  font-family: 'J&J CircularTT-Medium' !important;
}

.icon-wrapper {
  /* background-color: #fff;
    right: 3px; */
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  top: 0;
  width: 100px;
  background-color: transparent;
  padding: 0;
  height: 44px;
  /* justify-content: space-around; */
  /* padding-left: 7px; */
  /* right: 1px; */
  /* border-top: 2px solid #d8d8d8!important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
}

.search-icon-wrapper-webView {
  cursor: pointer;
  background: #0074B0 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 5%;
  height: 100%;
  margin-left: 10%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.magnifyingGlass-mobileView {
  width: 65%;
}

.search-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  color: #0074B0;
  border-radius: 3px;
  padding: 2px 5px;
  position: absolute;
}

.search-button-wrapper {
  position: sticky;
  margin: 2%;
  color: #fff;
  background: #0074B0;
  border-radius: 4px;
  border-color: #0074B0;
  height: 86%;
  outline: none;
}

.search-tip-text {
  font-size: 14px;
  padding: 1.5% 2% 0 3%;
}

.fa-search {
  height: 2.8em !important;
}

.headerLogo #tooltipdis {
  font-family: 'J&J CircularTT-Medium' !important;
}

#tooltipdis {
  width: 1718%;
  text-align: center;
  color: #333;
  background: #fff;
  position: absolute;
  z-index: 10000;
  padding: 10px;
  border-radius: 5px;
  transition: 100ms ease;
  border: 1px solid;
  font-size: 14px;
  line-height: 1.428571429;
}

#tooltipdis.right:before {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  top: -7px;
  left: 16px;
  margin-left: -10px;
  border-bottom: 7px solid #000;
}

#tooltipdis:before {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  bottom: -10px;
}

#tooltipdis.right:after {
  right: 4px;
  margin: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -6px;
  left: 15px;
  margin-left: -8px;
  border-bottom-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

#tooltipdis:after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  bottom: -10px;
}

.footer,
.header {
  color: #272727;
  text-align: left;
  position: absolute;
  right: 5px;
  left: 5px;
  border-top: 1px solid #dfdfff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background-color: #fff
}

.footercontent {
  margin: 10px 0%;
  width: 96%
}

.footerText {
  color: #808080;
  font-family: 'J&J CircularTT-Medium' !important;
}

.footerText1 {
  font-size: 14px;
  line-height: 1.42857143;
  padding: 0% 30%
}

.footerText2 {
  font-size: 12px;
  padding: 0% 30%;
  font-weight: 700;
}

.footerLinks {
  float: right;
  color: #808080;
  font-size: 14px;
  line-height: 1.42857143;
  font-family: 'J&J CircularTT-Medium' !important;
  box-sizing: border-box;
  margin: 0% -4%;
}

.footerLinks a {
  color: #0a58ca;
  text-decoration: none;

}

.footerLinks a:hover {
  text-decoration: underline;
}

.footerImg {
  float: left;
}

.footer-privacy-text {
  color: #0a58ca;
  cursor: pointer;
}

.footer-privacy-text:hover {
  text-decoration: underline;
  color: #0a58ca;
}

/*Header & Footer CSS End */

/*Registration Master CSS Start*/
/* .registrationLogo{
  float:left;
  padding:2% 2%;  
} */
.registrationWrapper {
  width: 100%;
  overflow-x: clip;
}

.registrationInnerWrapper {
  display: block;
  padding-bottom: 5%;
  font-family: 'J&J CircularTT-Medium' !important;
  /* margin: -1%; */
}

.registrationInnerWrapper .welcomePageTitle {
  color: #0088c3;
  font-size: 30px;
  /* padding: 12px 0; */
  margin: 0;
  width: 75%;
  /* margin-left: -7%; */
}

@media (min-width: 1800px) {
  .registrationInnerWrapper .welcomePageTitle {
    margin-left: -31%
  }
}

.registrationContainer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.continueButton:hover {
  background-color: #2EB000;
  color: #fff;
}

.buttonContainer {
  padding: 15px 0 45px 0;
}

.registrationSub-heading {
  margin: 5px 0 0 0;
}

/*Registration Master CSS End*/

/*Registration Welcome Tab CSS Start*/
.welcomeContent {
  display: block;
  /* padding-left: 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  margin: 12px 3% 25px 3%;
}

@media (min-width:1360px) and (max-width:2193px) {
  .welcomeMainHeader {
    color: #0d80b5;
    ;
    font-size: 30px;
    padding-top: 1%;
  }
}

.welcomeNxtBtn {
  float: right;
  margin: 0 15px 0 0;
}

.welcomeNxtBtn input[type="submit"] {
  display: inline-block;
  background: #0072c6;
  color: #fff;
  font-size: 14px;
  line-height: 33px;
  height: 33px;
  padding: 0 12px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none !important;
  border: white;
  font-family: 'J&J CircularTT-Medium' !important;
}

.welcomeMainHeader {
  color: #0d80b5;
  ;
  font-size: 30px;
}

.welcomeSubHeader {
  color: #0d80b5;
  ;
}

.welcome-select-content {
  overflow: hidden;
  margin: 12px 1% 0px 1%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9d9d9d4f;
  opacity: 1;
}

/*Registration Welcome Tab CSS End*/

/*Registration Location Tab CSS Start*/
.locationTitle {
  /* color: #0088c3;
    padding: 12px 0; */
  padding: 12px 0 12px 15px;
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 20px;
}

.locationSelectContent {
  display: block;
  /* padding:0 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  margin: 6px 1% 0px 1%;
  /* margin: 12px 1% 0px 1%; */
  /* margin: 10px 15px 0px 30px; */
}

.regionContainer {
  overflow: hidden;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9d9d9d4f;
  opacity: 1;
  margin: 6px 0 35px 0;
  /* margin: 12px 0 35px 0; */
}

.regionInnerWrapper {
  float: left;
  margin: 0px 0 16px 35px;
  /* margin: 10px 0 16px 50px; */
  /* margin: 16px 0 16px 50px; */
  /* width: 25%; */
  /* margin-right: 2px;
    padding: 12px;
    background: #f0f0f0; */
}

.regionDiv {
  /* padding: 0 0 12px; */
  /* padding: 0 0 20px; */
  display: flex;
  /* border-left: 1px solid #C5C5C5; */
  align-items: flex-end;
  padding: 0 0 30px 0;
  margin: 0 0 0 2px;
}

.regionContainer input[type="checkbox"] {
  float: left;
}

.regionContainer input[type="checkbox"]+span {
  display: block;
  overflow: hidden;
  padding-left: 10px;
  margin: 0 0 -40px 0;
}

.regionInnerWrapper span {
  font-weight: normal;
  color: black;
}

span.regionTxtChecked {
  font-weight: bold;
  /* color: #1ECB40; */
  color: rgb(0, 136, 195);
}

.countryWrapper {
  /* display: block;
  float: left;
  width: 74%;
  padding: 12px; */
  /* background: #f0f0f0; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  float: right;
  /* width: 70%; */
  width: 75%;
  margin: 10px 20px 25px 0px;
  /* margin: 10px 20px 25px 35px; */
  padding: 5px 15px;
  /* padding: 20px; */
  /* margin: 27px 22px 40px 35px;
  padding: 20px 50px; */
}

/* .countryListWrapper::before{
  content: " ";
  display:table;
}
.countryListWrapper::after{
  content: " ";
  display:table;
  clear:both;
} */
.countryListWrapper {
  /* max-height: 196px; */
  /* overflow: auto; */
  margin: 0 0 20px 0;
  /* max-height: 150px; */
}

.countriesSecondCol {
  /* margin: 0 0 0 5%; */
  margin: 0 0 0 15%;
}

.countriesListing {
  display: flex;
}

/* .countryPartDiv{ */
/* display: flex;
   flex-direction: column;  */
/* flex-wrap: wrap; */
/* align-content: flex-start; */
/* max-height: 196px;
  overflow: auto;
  margin: 0 0 20px 0; */
/* } */
.cntryDiv {
  /* min-width:17%;
  padding: 0 0 2px; */
  display: flex;
  /* border-left: 1px solid #C5C5C5; */
  /* align-items: flex-end; */
  padding: 0 0 30px 0;
  /* margin: 0 0 0 2px; */
}

.locationTitlePart {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 51px;
}

.locationContent {
  padding: 0px 51.5px 15px 51.5px;
}

.locationTextTitle {
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 15px;
}

.locationText {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  letter-spacing: 0px;
  /* color: #030506; */
  opacity: 1;
  text-align: left;
  margin: 10px 0 0 0;
}

.contentList {
  margin: 0px 15px 0px 0px;
}

.locationtexts {
  letter-spacing: 0px;
  color: #63666A;
  opacity: 1;
  text-align: left;
}

.locationRightArrow {
  margin: 0 0 -35px 0;
}

.topCircle {
  width: 6px;
  height: 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C5C5C5;
  opacity: 1;
  border-radius: 100%;
  /* margin: 0 0 0 -2px; */
}

.k-checkbox {
  margin: 0 0 -37px 15px;
  background: #fff !important;
  border: 1px solid #D5D5D5
}

.k-checkbox:checked {
  /* background: #349F00 !important; */
  /* background: #4DAD46 !important; */
  color: #fff !important;
  border: 1px solid #D5D5D5;
  background: rgb(0, 136, 195) !important;
}

.k-checkbox:focus {
  border-color: #4DAD46;
  box-shadow: none;
}

.k-checkbox:checked:focus {
  border-color: #4DAD46;
  box-shadow: none;
}

/* .locationCheckbox{
  margin: 0 0 -35px 15px;
} */
.regionTxt:hover {
  /* color: #6CC24A; */
  /* color: #2EB000; */
  /* color: #4DAD46; */
  /* color: #1ECB40; */
  color: rgb(0, 136, 195);
}

.locationChildText {
  letter-spacing: 0px;
  color: #63666A;
  opacity: 1;
  text-align: left;
  clear: both;
}

/*Registration Location Tab CSS End*/

/*Registration Function Tab CSS Start*/
.functionTitle {
  /* color:#0088c3;
  padding:12px 0; */
  padding: 12px 0 12px 15px;
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 20px;
}

.functionSelectContent {
  display: block;
  /* padding:0 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  /* margin: 12px 1% 0px 1%; */
  margin: 6px 1% 0px 1%;
  padding: 0px 51px 15px 51px;
}

.functionTabChoiceTxt {
  display: block;
  padding: 10px 0 0 26px;
  font-style: italic;
  letter-spacing: 0px;
  color: #63666A;
  opacity: 1;
  text-align: left;
}

.functionTitlePart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Registration Function Tab CSS End*/

/*Registration GDPR Tab CSS Start*/
.gdprTitle {
  /* color: #0088c3;
  padding: 12px 0; */
  padding: 12px 0 12px 15px;
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 20px;
}

.gdprAcceptanceContent {
  /* display: block;
  padding:0 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 6px 1% 0px 1%;
  /* margin: 12px 1% 0px 1%; */
  padding: 0px 51px 15px 51px;
}

.gdprLinktoPrivacy span {
  /* color: #428bca; */
  text-decoration: none;
  cursor: pointer;
  color: #0074B0;
}

.gdprLinktoPrivacy span:hover {
  /* color:#2a6496; */
  text-decoration: underline;
  color: #0074B0;
}

.radioTextWrapper {
  padding-left: 0.5%
}

.contentText {
  letter-spacing: 0px;
  color: #030506;
  opacity: 1;
}

.userTrackingListing {
  color: #0074B0;
  margin: 10px 0;
}

.continueText {
  letter-spacing: 0px;
  color: #6CC24A;
  opacity: 1;
  text-align: left;
}

.k-radio {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  opacity: 1;
  width: 14px;
  height: 14px;
  margin: 0 0 2px 0;
}

.k-radio:checked {
  /* background: #349F00 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  /* color: #349F00; */
  opacity: 1;
  /* border-color:#349F00; */
  /* width: 14px;
  height: 14px; */
  background: rgb(0, 136, 195) 0% 0% no-repeat padding-box;
  color: rgb(0, 136, 195);
  border-color: rgb(0, 136, 195);
}

.k-radio:checked:focus {
  /* border-color: transparent;
  box-shadow: none; */
  border-color: rgb(0, 136, 195);
  box-shadow: 0px 3px 6px #00000029;
}

.gdprTitlePart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectionContainer {
  padding-left: 2rem;
}

/*Registration GDPR Tab CSS End*/

/*Registration Page Tab Design CSS Start*/
.tab-list-item {
  display: inline-block;
  list-style: none;
  color: #FF8000;
  /* position: absolute; */
  margin-bottom: -1px;
  /* padding: 0.5rem 0.75rem; */
  background: url(../assets/tab-not-visited.png) no-repeat 100%;
  background-size: 94% 100%;
  width: 5%;
}

.tab-list-active {
  color: #fff;
  background-color: grey;
  background: url(../assets/Group_29414.png) no-repeat 100%;
}

.tabwrap {
  font-family: 'J&J CircularTT-Medium' !important;
  /* width:87%; */
  /* margin: 1% 1% 1% 1%; */
  margin: 1% 1% 0% 1%;
}

.nav-tabs1 {
  /* width: 100%; */
  display: flex;
  padding: 1% 2%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  flex-direction: column;
  align-items: center;
}

button.tab-list-active.tab-list-item {
  color: #FF8000;
  background: url(../assets/tab-not-visited.png) no-repeat 100%;
  width: 5%;
  background-size: 100%;
}

/* button.tab-list-item.tab-list-visited{
  color:#fff;
  background: url(../assets/tabVisited1.png) no-repeat 100%;
  width: 5%;
  background-size: 100%;
} */
button.welcomeNxtBtn {
  outline: none;
}

button.tab-list-item {
  cursor: default;
  list-style: none;
  padding: 0 0px;
  text-align: center;
  height: 46px;
  line-height: 48px;
  font-size: 15px;
  position: relative;
  font-weight: bold;
  border: none;
}

button.tab-list-item {
  outline: none;
  border: none;
}

button.tab-list.nav-tabs1 {
  outline: none;
}

.tab2 {
  z-index: 5;
}

.tab3 {
  z-index: 4;
}

.tab4 {
  z-index: 3;
}

button.tab-list-item.rwt__tab[aria-selected="true"] {
  color: #fff;
  background: url(../assets/Group_29414.png) no-repeat;
  position: relative;
  width: 5%;
  background-size: 98% 100%;
}

button.tab-list-item.rwt__tab[aria-selected="true"]:after {
  content: '';
  color: #fff;
  background: url(../assets/Group_29414.png) no-repeat;
  position: relative;
  width: 5%;
  background-size: 98% 100%;
}

button.tab-list-item.tab-list-visited {
  content: '';
  /* border-top-right-radius: 24px;
  border-bottom-right-radius: 24px; */
  color: #fff;
  background: none;
  background: url(../assets/Group_29414.png) no-repeat;
  width: 5%;
  background-size: 98% 99%;
}

.tab-list [type=button]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.tab-line-wrapper {
  position: relative;
  width: 20%;
}

.tab-line-image-wrapper {
  /* padding-top:6%; */
  width: 100%;
}

.tab-line-wrapper-between-text {
  width: 21%;
  position: relative;
}

.tab-list-item-text-visited {
  color: #2EB000;
  /* width: 5%; */
}

.tab-list-item-text {
  color: #63666A;
  /* width: 5%; */
}

.tab-text-wrap-inner {
  display: flex;
  width: 91%;
  align-items: center;
  padding-top: 0.2%;
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
  .tab-text-wrap-inner {
    display: flex;
    width: 90%;
    align-items: center;
    padding-top: 0.2%;
  }
}

.tab-wrap-inner {
  /* float:left; */
  display: flex;
  flex-flow: row;
  width: 90%;
  align-items: center;
}

/*Registration Page Tab Design CSS End*/

/*Registration Product Tab Design Start*/
.productTitle {
  /* color:#0088c3;
  padding:12px 0; */
  padding: 12px 0 12px 15px;
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 20px;
}

.productSelectContent {
  display: block;
  /* padding : 0 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 0.5% 1% 0px 1%;
  padding: 0px 51px 1% 51px;
}

.therapeuraticAreaContainer {
  overflow: hidden;
}

.therapeuraticAreaInnerWrapper {
  /* width: 30%; */
  line-height: 30px;
  margin-right: 2px;
  /* padding: 12px; */
  float: left;
  /* min-height: 225px; */
  width: 23%;
  min-height: 200px;
}

.step2-input {
  line-height: 30px;
}

.productWrapper {
  display: block;
  float: left;
  /* width: 69%; */
  padding: 12px;
  line-height: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  /* overflow: auto; */
  /* margin: 10px 0; */
  /* min-height: 225px; */
  /* max-height: 250px; */
  width: 76%;
  margin: 0 0 10px 0;
  min-height: 200px;
}

.productListWrapper::before {
  content: " ";
  display: table;
}

.productListWrapper::after {
  content: " ";
  display: table;
  clear: both;
}

.productPartDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

.productDiv {
  min-width: 17%;
  padding: 0 0 2px;
}

.ta-val span {
  padding: 1%;
  color: black;
  font-style: italic;
}

.ta-val span:hover {
  color: rgb(0, 136, 195);
}

.item-checked {
  color: rgb(0, 136, 195) !important;
  /* color: #1ECB40 !important; */
  font-style: italic;
  font-weight: bold;
}

.ta-val input[type="radio"]:checked+a span {
  padding: 1%;
  color: black;
  font-style: italic;
  font-weight: bold;
}

.ta-val input[type="checkbox"]+span {
  display: block;
  overflow: hidden;
  padding-left: 10px;
  margin: 0 0 -40px 0;
}

.class-product-section {
  padding-bottom: 25px;
  /* display: flex; */
  /* border-left: 1px solid #C5C5C5; */
  /* align-items: flex-end; */
  /* padding: 0 0 30px 0; */
  margin: 0 0 0 2px;
  width: 100%;
}

.class-product-section span {
  font-style: italic;
  vertical-align: middle;
  margin: 0px 1px 0px 10px;
  font-weight: bold;
}

.class-product-section span:hover {
  /* color: #1ECB40; */
  color: rgb(0, 136, 195);
}

.product-section {
  /* padding-top: 5px;
  padding-left: 40px; */
  /* line-height: 22px; */
  /* padding: 0;
  padding-top: 7%; */
  padding: 1.5% 0 0 1%;
}

.product-section span {
  font-style: italic;
  vertical-align: middle;
  margin: 0px 1px 0px 10px;
  font-weight: normal;
}

.product-section span:hover {
  /* color: #1ECB40; */
  color: rgb(0, 136, 195);
}

.ta-val input[type="radio"] {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  margin: 0.7% 0.2%;
}

/* .ta-val input[type="radio"] img{
  transform: rotate(0deg);
} */
.ta-val input[type="radio"]:checked+a img {
  cursor: pointer;
  transform: rotate(90deg);
}

.ta-val img {
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  transform: rotate(0deg);
  /* margin: 0 0 -28px 0; */
  /* margin: 0 0 -5px 0; */
}

.productsTitlePart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step1-content {
  text-align: left;
  letter-spacing: 0px;
  color: #63666A;
  opacity: 1;
}

.note-content {
  text-align: left;
  letter-spacing: 0px;
  color: #F80000;
  opacity: 1;
}

.TherapeuraticContainer {
  /* padding: 35px 0 20px 0; */
  padding: 15px 0 20px 0;
}

/* .ta-val{
  display: flex;
  border-left: 1px solid #C5C5C5;
  align-items: flex-end;
  padding: 0 0 30px 0;
  margin: 0 0 0 2px;
} */
div#prod {
  /* display: block; */
  /* overflow: hidden; */
  padding-left: 10px;
  /* margin: 0 0 -79px 0; */
  /* display: contents; */
  margin-top: 0px;
  padding-top: 20px;
  height: 35px;
}

div#prod:hover {
  color: #1ECB40;
}

.stepPart {
  display: flex;
}

.steparrow {
  margin: 0 10px 0 0;
}

.prd-val {
  display: flex;
  /* border-left: 1px solid #C5C5C5; */
  /* align-items: flex-end;  */
  /* padding: 0 0 30px 0; */
  margin: 0 0 0 2px;
  /* height: 15px; */
  /* padding-top: 20px; */
  border-left: none;
  align-items: flex-start;
  height: 25px;
  padding: 0;
}

.productsRightArrow {
  margin: 0 0 -5px 0;
}

/* .sub-productsName {
  margin: 20px 0 -10px 0;
} */
.arrowContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 15px 0 0;
}

.rightPartArrow {
  /* background: #6E7987 0% 0% no-repeat padding-box; */
  /* transform: matrix(-1, 0, 0, -1, 0, 0); */
  /* opacity: 1; */
  width: 20px;
  height: 8px;
  /* border-bottom: 1px solid; */
  /* margin: 0px 0 -5px 0px; */
  border-top: 1px solid #C5C5C5;
  margin: 10px 0 1px 0;
}

.downTriangleArrow {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #6E7987;
  /* border-bottom: 7px solid #6E7987; */
  border-radius: 5px;
  /* transform: rotate(
180deg); */
  width: 0;
  height: 0;
  /* margin: 0px; */
  margin: 0px 0 0px -10px;
  padding: 2px 0 0 0;
}

/* .productListWrapper {
  overflow: auto;
  max-height: 140px;
  //max-height: 200px;
} */
.classSection {
  display: flex;
  align-items: flex-end;
}

/*Registration Product Tab Design End*/

/*Registration Document Tab Design Start*/
.documentTitle {
  /* color:#0088c3;
  padding:12px 0; */
  padding: 12px 0 12px 15px;
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  font-size: 20px;
}

.documentSelectContent {
  display: block;
  /* padding: 0 8%; */
  text-align: left;
  /* width: 100%; */
  font-size: 14px;
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 6px 1% 0px 1%;
  /* margin: 12px 1% 0px 1%; */
  padding: 0px 51px 15px 51px;
}

.docDiv {
  padding: 0 0 1%;
  /* display: flex; */
  /* border-left: 1px solid #C5C5C5; */
  /* align-items: flex-end; */
  /* padding: 0 0 30px 0; */
  /* margin: 0 0 0 2px; */
}

.docDiv input[type="checkbox"] {
  /* margin:4px 0 0; */
  /* margin: 0 0 -20px 15px; */
  margin: 0px 0 2px 0;
  line-height: normal;
}

.docDiv span {
  /* margin: 30px 1px -23px 10px; */
  vertical-align: middle;
  font-weight: normal;
  /* display: block; */
  overflow: hidden;
  padding-left: 10px;
}

.docDiv span:hover {
  /* color: #1ECB40; */
  color: rgb(0, 136, 195);
}

span.docchckboxNameChecked {
  font-weight: bold;
  color: rgb(0, 136, 195);
  /* color: #1ECB40; */
}

.documentsTitlePart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documentsContent {
  text-align: left;
  letter-spacing: 0px;
  color: #63666A;
  opacity: 1;
}

.documentsRightArrow {
  margin: 0 0 -17px 0;
}

/*Registration Document Tab Design End*/

/*Contact Us CSS Start*/
.contactContentWrapper {
  box-sizing: border-box;
  font-family: 'J&J CircularTT-Medium' !important;
  text-align: left;
}

.contactHeader {
  /* font-size: 24pt; */
  font-size: 22px;
  color: #0D80B5;
  text-align: left;
  letter-spacing: 0.02px;
  opacity: 1;
}

.contactContent {
  color: #333
}

.contactContent a {
  color: #1076bc;
  cursor: pointer;
  text-decoration: none;
}

.contactContent h3 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.contactWrapper {
  margin: 0 1%;
}

/*Contact Us CSS End*/

/*Get Help CSS Start*/
.helpPageWrapper {
  margin: 0 1%;
}

.helpContentWrapper {
  /* padding-left: 20px; */
  box-sizing: border-box;
  font-family: 'J&J CircularTT-Medium' !important;
  text-align: left;
  color: #333
}

.helpContentWrapper h3 {
  /* font-size: 24px; */
  /* margin-top: 20px; */
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 22px;
  text-align: left;
  letter-spacing: 0.02px;
  color: #0D80B5;
  opacity: 1;
  /* padding: 10px 0px 0px 0px; */
}

.helpContentWrapper p {
  /* margin: 0px 60px 0px 30px; */
  font-size: 17.5px;
  line-height: 1.6;
  box-sizing: border-box;
  opacity: 1;
}

.helpContentWrapper a {
  /* color: #1076bc; */
  color: #030506;
  cursor: pointer;
  text-decoration: none;
}

.helpContentList {
  /* font-size: 16.5px; */
  font-size: 14px;
  line-height: 1.6;
  box-sizing: border-box;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

/*Get Help CSS End*/

/*Privacy Policy CSS Start*/
.pp-outer-wrapper {
  margin: 0 1.5%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  border-left: 5px solid #eee;
}

.pp-content-wrapper {
  margin: 2% 0 1% 0;
  padding: 0.2% 1.5% 0 1.5%;
  color: #333;
  font-family: 'J&J CircularTT-Medium';
  text-align: left;
  font-size: 17.5px;
}

.pp-header-wrapper {
  color: #0072C6;
  font-size: 30px;
  line-height: 1.4;
  font-weight: 500;
}

.pp-block-wrapper {
  font-size: 12.5px;
  ;
  overflow: auto;
  /* height: 350px; */
  text-align: justify;
  padding: 0.8% 3% 0 0;
}

.pp-block-wrapper p {
  line-height: 30px;
}

/*Privacy Policy CSS End*/

/*Modal Css Start*/
.modal-open .modal {
  overflow-y: hidden !important;
}

.modal-content {
  font-family: 'J&J CircularTT-Medium' !important;
  font-size: 13.5px;
  justify-content: none;
  bottom: 28px;
}

.review-custom-modal-wrapper {
  min-width: 1000px;
  height: 100vh !important;
  /* border-left: 7px solid #1DA1E5; */
}

/* .modal-dialog{
  height:100%;
  padding-left: -25px;
} */

/* .my-modal{
  height:100vh;
} */
.modal-review-user-details {
  /* background: #337ab7; */
  padding: 10px;
  text-shadow: -1px -1px 0 rgb(0 0 0);
  /* border-top-left-radius: 13px;
    border-top-right-radius: 13px; */
  color: white;
  background: #0074B0 0% 0% no-repeat padding-box;
  opacity: 1;
}

.review-wrap {
  /* border-left: 1pt solid lightblue;
  border-right: 1pt solid lightblue; */
  padding: 6px 0 0 0;
}

.review-title-span {
  /* background: lightblue;
  padding: 10px;
  text-align: center;
  border-bottom: 0.3pt solid lightgrey; */
  color: #333;
  font-weight: 600;
}

.review-value-span {
  /* background: aliceblue;
  color: #337ab7;
  padding: 6px;
  width: 100%;
  border-bottom: 0.3pt solid lightgrey; */
  letter-spacing: 0px;
  color: #0074B0;
  opacity: 1;
  padding-left: 6px;
}

.review-second-wrap {
  color: #337ab7;
  margin-top: -20px;
  background: aliceblue;
  width: 100%;
  border-bottom: 1pt solid lightblue;
  border-right: 1pt solid lightblue;
  border-left: 1pt solid lightblue;
}

.review-table2-title {
  padding: 10px 20px 10px 30px;
}

.review-table2-value {
  border-left: 1pt solid lightblue;
  padding: 10px 20px 10px 30px;
}

.btn-custom-class {
  background: #0072c6 !important;
  padding: 5px 5px;
  font-size: 14px !important;
}

.review-row-odd {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  opacity: 1;
  margin: 6px 0px;
  /* margin: 12px 0px; */
  padding: 10px;
}

.review-row-even {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  opacity: 1;
  /* margin: 12px 0px; */
  margin: 6px 0px;
  padding: 10px;
}

.left-border {
  /* border: 7px solid #1DA1E5;
  transform: matrix(0, 1, -1, 0, -492, 0); */
  border-left: 7px solid #1DA1E5;
  opacity: 1;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #9D9D9D4F;
  /* overflow-y: auto; */
}

/* .modal-content {
  border-left: 7px solid #1DA1E5;
} */
/* .modal-footer{
  padding:0.75rem 0.75rem;
} */
/*Modal Css End*/
/*Navigation Bar CSS Start*/
.dropdown-cutom-class {
  opacity: 0.9 !important;
  float: none;
  padding: 4% 0.5% !important;
  border: 1px solid rgb(221, 221, 221);
  min-height: 350px;
  width: 20%;
  pointer-events: all !important;
}

.dropdown-cutom-class a {
  color: #3f3f3f;
  font-size: 14px !important;
  font-family: 'J&J CircularTT-Medium' !important;
  line-height: 1.6;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
}

.dropdown-cutom-class a:hover {
  color: #f57e20 !important;
  cursor: pointer;
  text-decoration: underline;
  background: #fff;
}

.dropdown-cutom-class a:visited {
  background: #fff;
  color: #3f3f3f;
}

.dropdown-cutom-wrapper {
  width: 15rem;
}

/*Navigation Bar CSS End*/

/*Recommended page CSS Start*/
.recommended-contents-wrapper {
  display: flex;
  /* text-align: left; */
  padding: 1% 0;
  flex-direction: row;
  /* min-height: 60vh; */
  /* min-height: 140vh; */
  /* min-height: 200vh; */
  min-height: 57vh;
  margin: 1% 1% 0 1%;
  /* border: solid 1px #dcdcdc; */
}

/*Recommended page CSS End*/

/*Search Header CSS start*/
.tooltip-search-wrapper {
  color: #000;
  font-family: 'J&J CircularTT-Medium';
  padding-top: 1%;
}

.search-inform-icon {
  height: 26px;
  width: 26px;
  display: inline-block;
  position: absolute;
  /* top: 5px; */
  background: url(../assets/i-icon.png) no-repeat;
  cursor: pointer;
}

.search-inform-text {
  display: inline-block;
  margin-left: 31px;
  margin-top: 2px;
  font-size: 14px;
  cursor: pointer;
}

.search-input-below-wrapper {
  /* margin-left: -25% */
  /* background: transparent linear-gradient(90deg, #7DA1C8 0%, #7AAAD9 100%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(90deg, rgba(125, 161, 200, 0.57) 0%, rgba(122, 170, 217, 0.57) 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* opacity: 0.57; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2% 0 0 0;
  font-family: 'J&J CircularTT-Medium';
}

.search-input-below-wrapper-without-item {
  padding: 2% 0 0 0;
}

.tooltiptext {
  text-align: left;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  transition: opacity .9s;
  /* margin-top: -63px; */
  font-family: 'J&J CircularTT-Medium';
  font-size: 12px;
}

.search-text-info-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  left: 137px;
}

.tooltip-right {
  /* left: 42%; */
  background: #6E7987;
  /* padding: 15px; */
  border-radius: 3px;
  color: #fff;
  position: absolute;
  top: 40px;
  margin: 0;
}

.tooltip-right ul {
  background: #6E7987;
  list-style-type: decimal;
  width: 346px;
  /* height: 164px; */
  margin-bottom: 0;
  border-radius: 3px;
  /* top:15px; */
}

.search-help-text-tooltip {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 13px solid #6E7987;
  position: absolute;
  top: 28px;
  right: 35px;
}

.search-page-link-general {
  font-size: 14px;
  font-weight: bold;
  opacity: 0.93;
  text-align: center;
  float: right;
  margin-right: 28%;
  margin-top: -2%
}

.search-page-link-general a,
.search-page-link-general a:hover {
  color: #0a58ca;
  cursor: pointer;
}

.search-page-link-general a:visited {
  color: #0a58ca;
  text-decoration: none;
}

.search-input-above-wrapper {
  text-align: center;
  margin-top: -1%
}

.search-header-links {
  /* display: inline;
  font-size: 13px;
  font-weight: bold;
  padding: 3px;
  padding-left: 1%;
  opacity: 0.9;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #f1eeee 100%); */
  text-align: center;
  letter-spacing: 0.55px;
  color: #000000;
  opacity: 1;
  /* padding: 12px 15px 10px 5px; */
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 4px;
  word-break: break-word;
}

.search-header-links a,
.search-header-links a:hover {
  /* color: #337ab7; */
  color: #333;
  cursor: pointer;
  text-decoration: none;
}

.search-header-links a:visited {
  /* color: #0072c6; */
  color: #333;
  text-decoration: none;
}

.search-input-wrapper {
  /* padding: 1% 0 0 0; */
  font-family: 'J&J CircularTT-Medium';
  margin-left: -52%;
}
.search-input-gen-ai-button-wrapper{
  display: flex;
  margin-left: 0;
}

.bannerImage-left {
  width: 13%;
  margin: 0 40px;
  margin-top: -60px;
  padding: 5px;
  background: #2a498a;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  justify-content: center;
}


.gen-ai-answer-btn {
  width: 130px;
  border: 1px solid #00000029;
}
.gen-ai-answer-btn:hover{
  box-shadow:none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.searchSelect button {
  outline: none;
}

.searchSelect button,
.searchSelect button:hover,
.searchSelect button:focus,
.searchSelect button:active {
  /* background: url(../assets/dropDwnArw.png) no-repeat 96% center #dcdcdc; */
  /* background: url(../assets/dropdownArrow.svg) no-repeat 96% center #F4F4F4 padding-box; */
  /* background: url(../assets/dropdownArrow.svg) no-repeat 96% center #DEDEDE padding-box; */
  height: 44px;
  /* color: #000; */
  letter-spacing: 0.02px;
  color: #3F4444;
  line-height: 43px;
  /* border: 2px solid #d8d8d8!important; */
  /* width: 152px; */
  /* min-width: 92px; */
  font-size: 14px;
  padding: 0 10px 0 15px;
  font-family: 'J&J CircularTT-Medium';
  /* border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
  background: #F4F4F4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  /* background-position-y: 10px; */
  text-align: left;
  width: 184px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchSelect .btn:focus {
  /* box-shadow: none; */
  /* outline: 2px solid #000; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.searchSelect ul {
  list-style: none;
  text-align: left;
  position: absolute;
  ;
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  color: #000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  /* width: 155px; */
  padding: 6% 0 6% 0;
  cursor: pointer;
  z-index: 100;
  width: 180px;
}

.searchSelect ul li {
  padding: 2% 0 0 15%;
}

.searchSelect ul li:hover {
  background-color: #f2f2f2;
}

.input-box-wrapper {
  position: absolute;
  display: inline-block;
  width: 53%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.input-search-box {
  width: 100%;
  height: 44px;
  /* padding: 1% 0% 1% 1%;
  border-top-right-radius:5px;
  border-bottom-right-radius: 5px; */
  /* border: 2px solid #d8d8d8!important; */
  font-family: 'J&J CircularTT-Medium';
  outline: none;
  font-size: 18px;
  float: left;
  position: relative;
  color: #333;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 4px;
  /* padding: 10px 80px 10px 25px; */
  padding: 1% 12% 1% 1%;
  letter-spacing: 0.02px;
  /* color: #000000; */
  border: none;
  /* background: url('../assets/search-icon.svg') no-repeat 13px 13px;
  padding-left: 44px; */
}

/* .input-search-box::placeholder{
  color: #777;
} */
.input-search-cancel-icon {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin: 0 6%;
}

.fielded-search-icon-wrapper {
  position: absolute;
  display: inline-block;
  margin-left: 53.5%;
  /* padding-top: 0.6%;  */
}

/* .fielded-search-icon-inner-wrapper{
  background: #fff;
  padding: 20%;
  border-radius: 3px;
} */
.clear-search-button-wrapper {
  position: absolute;
  display: inline-block;
  margin-left: 57%;
  padding-top: 0.5%;
}

.suggestions-list-wrapper ul {
  list-style: none;
  text-align: left;
  position: absolute;
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  color: #000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  width: 100%;
  /* width: 53%; */
  /* padding: 6% 0 6% 15%; */
  cursor: pointer;
  z-index: 100;
  /* color: rgb(167, 162, 162); */

  background: #F9F9F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  letter-spacing: 0.02px;
  color: #3F4444;
}

.suggestions-list-wrapper {
  /* padding-top:0.2%; */
  /* padding-left:54.5%;
  display: block; */
  position: relative;
  top: 44px;
}

.suggestions-list-wrapper ul {
  padding: 0;
}

.suggestions-list-wrapper li {
  /* padding:2% 0 2% 3.5%; */
  /* padding: 2% 0 2% 1%; */
  border-bottom: 1px solid rgba(112, 112, 112, 0.21);
  /* opacity: 0.21; */
  padding: 1% 0 1% 1%;
}

.suggestions-list-wrapper li:hover {
  background-color: #e6f2fa;
}

.suggestions-list-active {
  background-color: #e6f2fa;
}

.suggestions-fielded-header {
  font-size: 14px;
  /* color: #7d7a7a; */
  padding: 2.5% 0 1% 1%;
  letter-spacing: 0.02px;
  color: #0074B0;
  text-transform: capitalize;
  opacity: 1;
}

.allFieldsListing {
  letter-spacing: 0.01px;
  color: #3F4444 !important;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0px !important;
}

.allFieldsListing li {
  border-top: 1px solid #D9D9D9;
  padding: 5px 0px 5px 15px !important;
}

.vline {
  border-left: 1px solid #707070;
  opacity: 0.44;
  height: 25px;
  margin: 0 7%;
}

.search-header-links-component {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: row;
  margin: 10px 20px;
  width: 14%;
}

.coronavirus-pink-icon {
  background: #FFDCF1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1px;
  opacity: 1;
  padding: 12px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.coronavirus-blue-icon {
  background: #D6EDFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1px;
  opacity: 1;
  padding: 12px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.coronavirus-purple-icon {
  background: #EDC9FF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1px;
  opacity: 1;
  padding: 12px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.coronavirus-yellow-icon {
  background: #FFE8AF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1px;
  opacity: 1;
  padding: 12px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.globalSearch-wrapper {
  margin: 2% 0 0 0;
}

/*Search Header CSS end*/

/*Results Page CSS start*/
.global-search-features-wrapper {
  height: 99px;
  background: #f2f2f2;
  font-family: 'J&J CircularTT-Medium';
  margin-top: 27px;
  /* display: flex;
  flex-direction: row; */
}

.clear-search-wrapper {
  float: right;
  padding: 3% 3% 2% 3%
}

.clear-search-button:hover {
  background-color: #e6f2fa;
  border-color: #92c0e0;
  ;
}

.clear-search-button {
  color: #444;
  background: #fdfdfd;
  ;
  border-radius: 15px;
  border: 1px solid #ababab;
  height: 38px;
  padding: 2px 32px;
  font-size: 14px;
}

.results-contents-wrapper {
  /* padding-top: 1%; */
  margin: 1% 1.2% 0 1%;
  /* margin:0 1.5%; */
  /* border:solid 1px #dcdcdc; */
}

.resultsInnerWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
  /* margin: 0 1%; */
}

.results-no-search-content {
  margin: 0.5% 1.5%;
  font-size: 14px;
}

.filterMenuWrapper {
  width: 10%;
  /* border:solid 1px #dcdcdc; */
  min-height: 25%;
  font-family: 'J&J CircularTT-Medium';
  border-bottom: none;
  transition: ease 0.15s all;
  transform: initial;
}

.filterMenuWrapperExpanded {
  width: 20%;
}

.filterMenuHeader {
  height: 42px;
  font-size: 18px;
  padding: 5px 20px;
  color: #2D86B5;
  background: #fff;
  line-height: 32px;
  position: relative;
  margin-top: -15px;
  clear: both;
  border: solid 1px #dcdcdc;
}
.filterMenuHeader {
  background: #2a498a;
  color:#ffffff;
}
div.filterMenuHeader > span:nth-child(3) > img{
  cursor: pointer;
}
.filterMenuHeader span {
  float: left;
}

.filter-menu-icon-wrapper {
  padding-right: 10px;
}

.filter-expand-icon-wrapper {
  background: rgba(217, 217, 217, 0.35) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  margin-left: 15px;
  padding: 0px 6px;
  cursor: pointer;
}

.filter-expand-icon-wrapper-desktop {
  /* background: rgba(217, 217, 217, 0.35) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029; */
  border-radius: 2px;
  margin-left: 15px;
  padding: 0px 6px;
  cursor: pointer;
}

.result-filter-expand-wrapper {
  height: 30px;
  cursor: pointer;
}

.result-filter-expand-wrapper img {
  border: solid 1px #dcdcdc;
  padding: 7%;
  background: #2a498a
}

.imsResultsMenuWrapper {
  width: 90%;
  /* border:solid 1px #dcdcdc; */
  font-family: 'J&J CircularTT-Medium';
  transition: ease 0.15s all;
  transform: initial;
}

.imsResultsMenuCollapse {
  width: 80%;
}

.imsResultsMenuHeader {
  height: 25px;
  font-size: 18px;
  cursor: pointer;
  padding: 1px 20px;
  color: #fff;
  background: #fa9546;
  /* line-height: 32px; */
  position: relative;
  margin-top: -15px;
  clear: both;
  margin-left: 1%;
  text-align: center;
  float: left;
  width: 49%
}

.pubmedResultsMenuHeader {
  border: 1px solid #B4B4B4;
  background: #fff;
  color: #B4B4B4;
  cursor: pointer;
  /* line-height: 32px; */
  position: relative;
  margin-top: -15px;
  height: 25px;
  font-size: 18px;
  padding: 0 20px;
  text-align: center;
  float: right;
  width: 49%
}

.resultsContentWrapper {
  display: flex;
  text-align: left;
  padding: 1% 0 1% 1%;
  flex-direction: column;
  width: 100%;
}

.resultDetailsDiv {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.results-wrapper {
  /* height: 85vh;
  overflow-y: auto; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* box-shadow:0px 5px 8px 2px #00000029; */
  opacity: 1;
  word-break: break-word;
  margin: 0.7% 0 1% 0;
}

@media screen and (max-width:600px) {
  .results-wrapper {
    height: 85vh;
    overflow-y: auto;
    word-break: break-word;
    /* overflow-x: hidden; */
  }
}

/* .resultDetailsDiv:hover{
  background: #dcdcdc;
} */
.resultsDetailsDivWrapper {
  padding-left: 1%;
  display: flex;
  flex-direction: row;
  padding-right: 1%;
  width: 105%;
}

.resultsDetailsDivWrapper:hover {
  background: #f2f2f2;
}

.resultDetailsDiv a {
  color: #0074B0;
  font-weight: bold;
  font-size: 14px;
  display: inline;
  word-break: break-word;
  cursor: pointer;
  text-decoration: none;
}

.resultDetailsDiv a:hover {
  color: #0074B0;
  font-weight: bold;
  font-size: 14px;
  display: inline;
  word-break: break-word;
  cursor: pointer;
  text-decoration: underline;
}

.resultsDivFooter {
  margin: 20px 0;
  height: 1px;
  border-bottom: 1px solid #dcdcdc;
  width: 105%;
  float: left;
}

.PHM-MetaDataProperty {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.PHM-MetaDataProperty label {
  display: contents !important;
}

.PHM-MetaDataProperty-SubType {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin-left: 30px;
}

.PHM-MetaDataProperty-SubType label {
  display: contents !important;
}

@media screen and (max-width:1000px) {
  .PHM-MetaDataProperty {
    font-size: 13px;
    line-height: 1.42857143;
  }

  .resultDetailsDiv a:hover {
    font-size: 13px;
  }

  .resultDetailsDiv a {
    font-size: 13px !important;
  }
}

.result-Description {
  /* padding-bottom: 12px; */
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  width: 75%
}

.wrapDescription {
  word-break: break-word;
  display: inline-block;
  width: 80%
}

.expandCollapseIcn {
  cursor: pointer
}

.results-count-wrapper {
  /* padding:0.5% 0%; */
  padding: 0.5% 1%;
  font-size: 14px;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-end; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 5px 8px 2px #00000029; */
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 100%;
  /* -webkit-justify-content: flex-end; */
}

/* .results-number-show-wrapper{ */
/* margin-top:2.5%;
  width: 100%; */
/* text-align: center; */
/* } */
.showingResultsCountContainer {
  display: flex;
  /* width: 235px;
  justify-content: flex-end; */
  align-items: center;
  margin: 0 15px 0 0;
}

.pagination-select-label {
  color: #0074B0;
  font-weight: 600;
  font-size: 15px;
  width: 70%;
  padding-top: 2px;
  display: flex;
  align-items: center;
}

.pagination-select-wrapper {
  border: 2px solid #0074B0;
  border-radius: 5px;
  /* height: 26px; */
  width: 100%;
  height: 100%;
}

.pagination-select-outer-wrapper {
  border: 1px solid #0074B0;
  border-radius: 5px;
  float: left;
  padding-left: 2%;
  display: flex;
  width: 100%;
}

.citation-outer-wrapper {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin: 0% 1%;
}

.citation-action-icn-wrapper {
  margin-top: -10px;
  padding-left: 10px;
}

.citation-action-icn-wrapper img {
  width: 18px;
  height: 10px;
  cursor: pointer;
  margin-left: -2px;
}

.citation-info-icon-wrapper {
  height: 17px;
  width: 17px;
  float: left;
  margin-left: -20px;
  margin-top: 1px
}

.citation-results-meta-property-wrapper {
  padding-top: 1%;
}

.results-title-action-outer-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 0.5%;
}

.results-div-docTitle-wrapper {
  flex: 1;
  padding-right: 1%;
}

.results-div-action-bar-wrapper {
  width: 15%;
}

.action-items-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /* margin:0 3%; */
  /* justify-content: space-evenly; */
  /* border:1px solid #F8F8F8; */
  /* box-shadow:5px 2px 3px 2px #D9D9D9; */
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 5px 8px 2px #00000029; */
  /* box-shadow: 0px 3px 6px #D9D9D9; */

  width: 100%;
  align-items: center;
  /* background: #fff; */
}

.action-items-inner-wrapper {
  padding: 8% 3%;
  cursor: pointer;
  transition: 0.15s;
  width: 100%;
  text-align: center;
  /* margin-bottom:15%; */
  /* border-left:1px solid #C1C1C1;
  border-right:1px solid #C1C1C1; */
}

.action-items-inner-wrapper a {
  /* padding-left:32%; */
  text-decoration: none !important;
}

.action-items-inner-wrapper:hover {
  color: #333;
  /* background: #dedede; */
  cursor: pointer;
}

/* .action-items-inner-wrapper img{
  height: 15px;
  width: 15px;
} */
.action-items-title {
  text-align: center;
  font-size: 14px;
  font-family: 'J&J CircularTT-Medium';
  color: #4D4D4C;
  margin: 0 0 0 10%;
  /* font-weight: bold; */
  /* padding-top:6% */
}

.action-items-supportingDocument-title {
  text-align: left;
  font-size: 14px;
  font-family: 'J&J CircularTT-Medium';
  color: #4D4D4C;
  margin: 0 0 0 5%;
}

.action-items-main-text {
  color: #F57F20;
  font-size: 15px;
  /* padding-right:5%; */
}

.quick-action-divider {
  /* border: 1px solid #00000029; */
  width: 95%;
  border-top: 2px solid #C1C1C1;
  ;
  /* height: 15px; */
}

.citation-share-custom-modal-wrapper {
  min-width: 750px;
  font-family: 'J&J CircularTT-Medium';
}

.citation-share-icon-content-wrapper {
  padding: 2% 0 0 16%;
}

.citation-share-icon-element-wrapper {
  cursor: pointer;
  display: inline-block;
  /* margin-right: 120px; */
}

.citation-share-icon-element-text-wrapper {
  padding: 5px;
  color: #337ab7;
}

.citation-share-pop-close-button {
  width: 30px;
  height: 30px;
  padding-top: 4px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: ease 0.25s all;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 1000px;
  background-color: #f3901d;
  font-size: 20px;
  font-family: Arial, Sans-Serif;
  text-align: center;
  line-height: 100%;
  color: #fff;
  cursor: pointer;
}

.citation-share-pop-close-button:hover {
  background-color: #337ab7;
}

.citation-share-pop-header-inner-wrapper {
  padding: 0% 2% 0 2%;
}

.citation-share-pop-header {
  color: #337ab7;
  font-size: 18px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  margin: 20px 0 0 0;
}

.popup-transition-share-mob {
  width: 93.5% !important;
}

.popup-transition-share-tab {
  width: 90% !important;
  max-width: none !important;
}

.share-popup-modalBody-mob-tab {
  background: transparent linear-gradient(90deg, rgba(217, 224, 238, .22), rgba(154, 230, 224, .22)) 0 0 no-repeat padding-box;
}

.document-descriptor-wrapper {
  font-family: 'J&J CircularTT-Medium';
  padding-bottom: 5px;
}

.document-descriptor-header-wrapper {
  margin: 5px 0px 3px;
  color: white;
  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(227, 152, 67, 0.99) 0%, rgba(243, 144, 29, 0.73) 100%);
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  text-shadow: rgb(204 132 24) 2px 2px;
}

.document-descriptor-img {
  width: 17px;
  height: 17px;
  padding-bottom: 3px;
  padding-left: 2px;
  transition: all 0.3s ease 0s;
  transform: rotate(0deg);
}

.document-descriptor-img-rotate {
  width: 17px;
  height: 17px;
  padding-bottom: 3px;
  padding-left: 2px;
  transition: all 0.3s ease 0s;
  transform: rotate(90deg);
}

.document-descriptor-text-wrapper {
  padding: 14px;
  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(252, 221, 1, 0.26) 0%, rgba(252, 222, 11, 0.29) 100%);
  font-size: 13.5px;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.document-descriptor-divider {
  border-top: 1px solid #dfa400bd;
  padding: 2px;
  margin: 6px 0px 6px 0px;
}

.keyTakeaways-info-icn {
  height: 22px;
  width: 22px;
  border-radius: 14px;
  margin-right: 5px;
  float: right;
}

.citation-right-find-descriptor-wrapper {
  font-family: 'J&J CircularTT-Medium';
  padding-bottom: 5px;
  display: inline-flex;
  flex-direction: row;
}

.citation-right-find-descriptor-header-wrapper {
  background: #5e8ebd;
  color: white;
  padding-left: 8px !important;
  padding: 4px;
  text-align: left;
  border-radius: 6px;
  cursor: pointer;
}

.order-info-icn {
  height: 17px;
  width: 17px;
  border-radius: 14px;
  margin-right: 5px;
  float: right;
  margin-top: 2px;
  vertical-align: middle;
}

.relevance-score-wrapper {
  font-family: 'J&J CircularTT-Medium' !important;
}

.relevance-highlight-text {
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  top: -1px;
  padding-left: 4px;
}

.high-relevance-color-box {
  background-color: #9ddd58;
  width: 10px;
  height: 10px;
  padding: 5px;
  display: inline-block;
}

.medium-relevance-color-box {
  background-color: orange;
  width: 10px;
  height: 10px;
  padding: 5px;
  display: inline-block;
}

.low-relevance-color-box {
  background-color: #ffff3a;
  width: 10px;
  height: 10px;
  padding: 5px;
  display: inline-block;
}

/*Results Page CSS End*/
/*Document Page CSS Start*/
.document-tab-wrapper {
  width: 99%;
}

.document-tab-inner-wrapper {
  display: block;
  padding-top: 2%;
  padding-bottom: 5%;
  font-family: 'J&J CircularTT-Medium' !important;
  margin: -1%;
}

.document-tab-inner-wrapper a:hover {
  text-decoration: underline !important;
}

.document-header-wrapper a {
  text-decoration: none;
  cursor: pointer;
}

.document-header-wrapper {
  padding-top: 5%;
  padding-bottom: 5%;
}

.document-tab-disclaimer-text {
  color: #f3901d;
  font-size: 14px;
  padding: 3% 7% 5% 7%;
  text-align: left;
  font-style: italic;
}

.document-tab-content-upper-card {
  padding-bottom: 40px;
  border-bottom: 1px solid #1da1e5;
  margin: 0 7% 1% 7%
}

/*Document Page CSS End*/
/*Admin Approve Page CSS Start*/
.admin-dashboard-content-wrapper {
  margin: 0 1%
}

.admin-data-table-wrapper {
  margin: 1% 0;
  font-family: 'J&J CircularTT-Medium';
}

.admin-button-custom-class {
  width: 75px;
  height: 36px;
}

.react-bootstrap-table table {
  table-layout: fixed;
  overflow: auto;
  min-width: 135%;
}

.react-bootstrap-table tbody tr {
  border-bottom: 1px solid #808080;
}

.react-bootstrap-table tbody td {
  font-size: 14px;
  word-wrap: break-word;
  text-align: center;
}

.react-bootstrap-table table td {
  white-space: pre-wrap !important;
}

/* .react-bootstrap-table table th{
  white-space: pre-wrap !important;
}  */
.react-bs-table-tool-bar {
  text-align: left;
}

/* .react-bs-table-csv-btn-wrapper { */
/*Export CSV button styling*/
/* text-align: left;
  padding-bottom: 0.5% */
/* } */

.react-bs-table-csv-btn-wrapper button:focus {
  box-shadow: 0 0 0 0.05rem #808080;
}

.react-bs-table-csv-btn {
  /* background-color: #007600 !important;
  color: #fff !important;
  height: 38px; */
  color: #0074B0 !important;
  border: 1px solid #0074B0 !important;
  background: #fff !important;
  min-width: 70px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 1px 6px !important;
}

.react-bs-table-csv-btn:hover {
  color: #fff !important;
  background: #0074B0 !important;
}

.search-label {
  padding-bottom: 0.5%;
}

.search-label span {
  /*Hide the Label of Search*/
  display: none;
}

.react-bootstrap-table-pagination {
  text-align: left;
  padding: 1% 0 1% 0;
}

.react-bootstrap-table thead tr {
  border-bottom: 2px solid #808080;
  border-top: 2px solid #808080;
}

.react-bootstrap-table th {
  /* color: #1076bc; */
  color: #0074B0;
  width: 105px;
}

.react-bootstrap-table tbody td {
  color: #333;
}

.react-bootstrap-table>table>thead>tr>th .filter-label .sr-only {
  display: none !important;
}

.my-custom-text-filter {
  color: #333 !important;
  font-size: 14px;
  text-align: center;
}

.form-control:focus {
  border: none !important;
  box-shadow: 0 0 0 0.05rem #808080 !important;
}

.page-item.active .page-link {
  background-color: #808080 !important;
  border-color: #808080 !important;
  color: #fff !important;
}

.page-link {
  color: #333 !important;
}

.react-bootstrap-table-pagination-total {
  padding-left: 0.5%;
  color: #333;
  font-size: 14px;
}

.dropdown-toggle {
  background-color: #808080 !important;
  border-color: #808080 !important;
}

.react-bs-table-sizePerPage-dropdown button {
  font-size: 14px;
}

.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem #808080 !important;
}

.react-bs-table-sizePerPage-dropdown .dropdown-item a {
  color: #333;
  text-decoration: none;
  display: block;
}

.admin-data-function-button-wrapper {
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  padding: 1%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 1;
  margin: 0 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-PopUp-inner-wrapper {
  /* position: fixed ; */
  margin: auto;
  height: 25%;
  width: 30% !important;
  text-align: center;
}

.admin-page-authorization-wrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  color: #333;
  font-family: 'J&J CircularTT-Medium';
}

.admin-console-heading {
  letter-spacing: 0.02px;
  color: #0D80B5;
  text-transform: capitalize;
  opacity: 1;
  font-size: 20px;
  text-align: left;
}

.admin-heading-leftPart {
  width: 70%;
  text-align: left;
}

.admin-console-button-container {
  display: contents;
}

.adminconsole-table {
  /* max-height: 375px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
}

.adminDashboard-table {
  min-width: 230% !important;
}

.adminDashboard-table>thead {
  background: #FCFCFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
}

.admin-data-table-wrapper>div>.react-bootstrap-table-pagination {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 1;
  margin: 12px 0;
  padding-bottom: 0;
}

/*Admin Approve Page CSS End*/
/*Filter CSS Start*/

.scrollable {
  overflow: auto;
}

.filter-menu-content {
  margin-bottom: 25px;
  color: var(--dark-grey);
  border: solid 1px #dcdcdc;
  min-height: 44vh;
}

.filter-item {
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 5px 0;
  margin: 3px 20px 3px 0px; */
  padding: 10px 20px 10px 18px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.21);
}

.filter-text {
  font-size: 14px;
  margin-right: 5px;
  transform: none;
  transition: transform 0.1s;
}

.filter-text-selected {
  background: #FFE2CB;
  color: #333;
  font-size: 14px;
  /* margin-right: 20px; */
  font-weight: bold;
  transform: none;
  transition: transform 0.1s;
}

.filter-item:hover {
  /* transform: translateX(5px); */
  color: #333;
  background: #dbf7fd;
  /* background: #FFE2CB; */
}

.select-filter-item {
  color: #333;
  font-size: 14px;
  background: #FFE2CB;
  /* margin-right: 20px; */
  transform: none;
  transition: transform 0.1s;
  position: relative;
}

.filter-popup-button {
  display: flex;
  align-items: center;
  margin-top: 15px;
  border: none;
  background: #fff;
}

button:disabled.filter-popup-button.clickable {
  cursor: not-allowed;
}

.filter-popup-button-text {
  border: 1px solid black;
  width: 100%;
  border-radius: 19px;
  padding: 8px;
  color: black;
  font-size: 12px;
}

.filter-modal-wrapper {
  background-color: white;
  border: 1px solid #dcdcdc;
  font-family: 'J&J CircularTT-Medium';
  /* width: 700px; */
  width: 560px;
  /* margin-top: -42px; */
}

.filter-modal-wrapper-publication-filter {
  /* width : 700px; */
  width: 560px;
}

.filter-modal-wrapper .filter-category {
  background-color: #fff;
  color: #0074B0;
  height: 42px;
  padding: 20px 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.filter-category-text {
  /* max-width: 400px; */
  max-width: 350px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-block;
}

.filter-times button {
  cursor: pointer;
  border: none;
  background: none;
}

.filter-modal-wrapper .filter-icon-times {
  font-size: 15px;
}

.filter-modal-wrapper .filter-modal-body {
  padding: 25px 0px 25px 20px;
}

.filter-modal-wrapper .filter-modal-body-inner-wrapper {
  /* max-height: 300px; */
  max-height: 400px;
  /* max-height: 200px; */
  overflow-y: auto;
  min-height: 140px;
  /* padding-top:15px; */
}

.filter-modal-wrapper .apply-filter {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 5px 5px;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
  background: #FF8000;
  transition: 0.5s;
}

.apply-filter:disabled {
  cursor: not-allowed;
}

.filter-modal-wrapper .apply-filter:hover:enabled {
  background: #fff;
  color: #FF8000;
  border: 1px solid #FF8000;
}

/* .filter-modal-wrapper .apply-filter:disabled{
  border: 1px solid #dadada;
  color:#888B8D;
  background: #fff;
} */
.filter-modal-wrapper .filter-popup-item {
  font-size: 13px;
  font-size: 13px;
  /* border-left: 1px solid #C5C5C5;
  display: flex; */
  margin: 0 0 0 2px;
  flex-direction: column;
}

.filter-modal-wrapper .filter-popup-item-button {
  display: flex;
  align-items: flex-start;
  /*  border-left: 1px solid #C5C5C5; */
  /* height: 25px; */
  height: 30px;
  padding-top: 10px;
}

.filter-modal-wrapper .filter-popup-subitem {
  margin-left: 15px;
  /* border-left: 1px solid #C5C5C5; */
}

.filter-modal-wrapper .filter-popup-item-caret {
  margin-top: 2px;
  padding: 5px 5px 5px 0;
  border: none;
  background: #fff;
  /* margin-left: -9px; */
}

.filter-modal-wrapper .filter-popup-item-label {
  display: flex;
  padding: 5px;
}

.filter-modal-wrapper .filter-popup-item-label input {
  margin-top: 3px;
  margin-right: 7px;
}

.filter-modal-wrapper .filter-popup-item-caret.hide-caret {
  visibility: hidden;
  pointer-events: none;
  /* margin-left: -9px; */
}

.filter-modal-wrapper .filter-popup-item-label input[type="checkbox"] {
  cursor: pointer;
}

.filter-selection-display-wrapper {
  font-family: 'J&J CircularTT-Medium';
  color: #333;
  padding: 0.7% 0% 0.7% 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin-bottom: 1%;
}

.filter-results-display-wrapper {
  padding: 10px 0;
}

.filter-container {
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  /* margin-left: 18px; */
  /* margin-top: 10px; */
  text-align: left;
}

.filter-sub-category-wrapper {
  line-height: 15px;
  width: 100%;
}

.filter-sub-category-wrapper-publication-year {
  line-height: 15px;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  max-height: 275px;
}

.reset-select-filter-wrapper {
  align-items: center;
  /* margin-top: 1rem; */
  margin-bottom: 0;
  border: 1px solid #0074B0;
  border-radius: 7px;
  padding: 0rem 0.5rem;
  color: #0074B0;
  font-size: 15px;
  background-color: #fff;
  /* margin-left:115px; */
}

.filter-nav-horizontal-line {
  border: 1px solid #C5C5C5;
  margin: 14px 5px 5px 0;
}

.filter-name-text:hover {
  color: #2EB000;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-caret-right {
  width: 0.5em !important;
  height: 1.25em !important;
}

.svg-inline--fa.fa-caret-down {
  width: 0.8em !important;
  height: 1.25em !important;
}

.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.svg-inline--fa.fa-w-6 {
  width: 1.25em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.filter-display-remove-button-wrapper {
  float: right;
  padding-right: 2%;
}

.filter-selection-display-wrapper .clear-all-button {
  align-items: center;
  /*  margin-top: 1rem; */
  margin-bottom: 0;
  border: 1px solid #0074B0;
  border-radius: 7px;
  padding: .0rem .5rem;
  color: #0074B0;
  font-size: 15px;
  background-color: #fff;
}

.remove-filter {
  color: #979797;
  /* font-size: 18px; */
  /* padding-left: 1%; */
  vertical-align: middle;
  height: 18px;
}
.remove-fieldedsearch {
  color: #979797;
  /* font-size: 18px; */
  /* padding-left: 1%; */
  vertical-align: middle;
  height: 18px;
  margin-left: 2px;
}
.facet-item-wrapper {
  padding: 0% 1% 0% 0%;
  /* border: 1px solid #dadada;
  border-radius: 7px; */
  margin: 0.5% 1% 0.5% 0;
  /* border-left: solid 7px #0074B0; */
}

.facet-inner-wrapper {
  display: inline;
  /* white-space: nowrap; */
  overflow: hidden;
  vertical-align: middle;
  /*  padding-top:1.5px; */
  padding-bottom: 1.5px;
  /* padding:1.5px 0; */
  /* margin-bottom: -0.6%; */
}

.filter-display-item-wrapper {
  font-size: 13px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  /* max-width: 98%; */
  max-height: 70px;
  overflow-y: auto;
}

.filter-divider {
  /* height: 18px; */
  display: inline-block;
  /* border-left: 2px solid #bdbdbd; */
  margin: 0 5px;
  vertical-align: middle;
  line-height: 1;
}

.parent-filter-divider {
  height: 18px;
  display: inline-block;
  border-left: 2px solid #979797;
  margin: 0 0;
  vertical-align: middle;
  line-height: 1;
}

.subfilter {
  color: #333;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  background: #F5F5F5;
  border-radius: 10px;
  margin-left: 4px;
  padding: 2px;
}

.subfilter-wrapper {
  display: inline;
  /* flex-flow:row wrap;
  width: 100%; */
  align-items: center;
}

.subfilter-text {
  padding: 0 4px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-block;
  box-sizing: border-box;
  max-width: 480px;
}

.subfilter-divider {
  height: 24px;
  border: 1px solid #00000029;
  margin: 0 4px;
}

.parentFilter {
  /* margin-left: 10px; */
  color: #FFFFFF;
  background: #B2B2B2;
  padding: 5px 8px;
  /* font-weight: bold; */
  vertical-align: middle;
  border-left: solid 7px #0074B0;
  /* border-right:2px solid #f2f2f2; */
}

.applied-filter-header-section {
  border-bottom: 1px solid #00000029;
  padding-bottom: 0.4%;
}

.applied-filter-header-text {
  padding-left: 2%;
  font-size: 16px;
  color: #0074B0;
}

/*Filter CSS End*/
/*Gradient Loader CSS Start*/
.gradient-loader {
  background: -webkit-linear-gradient(180deg,
      #333,
      #bdb0b0,
      #ddd);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  height: 70vh;
  margin: 0 1.5%;
  border-radius: 4px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/*Gradient Loader CSS end*/
/*Pagination CSS start*/
.pagination-region {
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  margin: 15px 0;
}

.pagination-region .pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-region .anchor {
  width: 80px;
}

.pagination-region .current-page {
  color: #fff;
  background: #1076bc;
  border: 1px solid #1076bc;
}

.pagination-container button:disabled {
  user-select: none;
  cursor: not-allowed;
}

.pagination-region .anchor,
.pagination-region .page {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  height: 32px;
  line-height: 1em;
  font-size: 14px;
  margin: 0 3px;
  padding: 0 15px;
  white-space: nowrap;
}

.clickable {
  cursor: pointer
}

/*Pagination CSS End*/
/*Global default Product CSS Start*/
.content-shortcut-wrapper {
  width: 90%;
  float: left;
  margin-top: -15px;
}

.content-shortcut-wrapper-collapsed {
  width: 80%
}

.global-product-wrapper {
  font-family: 'J&J CircularTT-Medium';
  background: #5e8ebd;
  height: 43px;
  padding: 5px 20px;
  margin-top: -15.2px;
  text-align: left;
}

.global-product-text {
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  color: white;
}

.default-product-text-wrapper {
  background-color: #f36e1e;
  outline: none;
  color: white;
  box-shadow: 0 2px #ff8000;
  transform: translateY(1px);
  text-transform: uppercase;
  font-weight: 700;
  padding: 0px 10px;
  border-radius: 5px;
  margin-right: 6px;
  display: inline-block;
  margin-bottom: 9px;
  height: 25px;
  margin-top: 2px;
  line-height: 30px;
}

.global-product-custom-modal-wrapper {
  min-width: 850px;
  min-height: 100vh !important;
}

.global-product-popup-body {
  font-size: 14px;
  color: #333;
  line-height: 1.42857143;
}

.global-product-popup-body a:hover {
  text-decoration: underline !important;
}

.global-product-popup-body ol {
  list-style-type: decimal;
  text-align: left;
  display: table;
  overflow: hidden;
}

.global-product-popup-body li {
  float: left;
  padding: 2px;
}

.global-product-all-product-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.5% 0.5% 0 1.5%
}

.global-select-product-wrapper {
  background-color: #ccc;
  color: white;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 12px;
  border-radius: 12px;
  margin-right: 6px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}

.global-select-product-wrapper:hover {
  background-color: #f36e1e;
  outline: none;
  color: white;
}

.global-default-product-selected {
  background-color: #f36e1e;
  outline: none;
  color: white;
}

.global-product-cancel-button {
  background-color: #e5f1f9 !important;
  color: #343434 !important;
  border: #e5f1f9 !important;
  outline: none;
}

.global-product-btn {
  width: 75px;
  font-weight: bold;
}

/*Global default Product CSS End*/

/*Content Shortcuts CSS Start*/
.wrapper-class-for-content-shortcuts {
  font-family: 'J&J CircularTT-Medium';
  text-align: left;
  font-size: 14px;
  padding: 1% 1%;
}

.c19-content-list li::marker {
  color: #000;
  vertical-align: middle;
  font-size: 18px;
}

.c19-content-list li {
  padding: 0.2%;
}

.c19-content-list li a {
  color: #1076bc;
  cursor: pointer;
  text-decoration: none;
}

.c19-content-list li a:hover {
  text-decoration: underline;
}

.content-shortcut-all-product-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1% 0 0 0;
}

.c19-select-product-wrapper {
  background-color: #ccc;
  color: white;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 12px;
  border-radius: 12px;
  margin-right: 6px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}

.c19-select-product-wrapper:hover {
  background-color: #0074a9;
  outline: none;
  color: white;
}

.c19-product-selected {
  background-color: #0074a9;
  color: white;
  box-shadow: 0 2px #004d85;
  transform: translateY(1px);
}

/*Content Shortcuts CSS End*/

/* Content Shortcut Home Page Card CSS Start */
.contentShortcutWrapper {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  /* width: 99%; */
  /* height: 260px; */
  /* margin: 6px 0px 70px 7px;
  padding: 2px 0px 40px 0px; */
  margin: 0% 1% 1% 1%;
  font-family: 'J&J CircularTT-Medium';
  /* padding: 12px 30px 40px 27px; */
}

.contentShortcutWrapper-desktop {
  /* background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F; */
  opacity: 1;
  /* width: 99%; */
  /* height: 260px; */
  /* margin: 6px 0px 70px 7px;
  padding: 2px 0px 40px 0px; */
  margin: 0% 1% 1% 1%;
  font-family: 'J&J CircularTT-Medium';
  /* padding: 12px 30px 40px 27px; */
  border:1px solid rgba(112,112,112,0.21);
}

.contentShortcutHeader {
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 0%;
  cursor: pointer;
  /* padding: 0 0 7px 0; */
  height: 42px;
}

.contentShortcutHeader-desktop {
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 0%;
  cursor: pointer;
  /* padding: 0 0 7px 0; */
  background: #2a498a;
}

.headerCenterPart{
  font-size:22px;
  letter-spacing:0.02px;
  color: white;
  opacity: 1;
  font-weight: 400;
  font-family: 'J&J CircularTT-Medium' !important;
}

.headerLeftPart {
  font-size: 22px;
  letter-spacing: 0.5px;
  /* color: #000307; */
  color: #333;
  opacity: 1;
  font-weight: 400;
  /* padding: 0px 27px; */
  padding: 0% 0.5%;
  font-family: 'J&J CircularTT-Medium' !important;
}

.headerRightPart {
  color: #333;
  font-weight: 500;
  padding: 0.5% 1.3% 0% 0%;
  /* padding: 0% 1.5% 0% 0%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 20%; */
  width: 15%;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'J&J CircularTT-Medium' !important;
}

@media only screen and (max-width : 1366px) {
  .headerRightPart {
    width: 21%;
  }
}

@media only screen and (max-width: 1000px) {
  .headerRightPart {
    width: 21%;
  }
}

.viewMoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-evenly; */
  /* width: 85%; */
  cursor: pointer;
}

.viewMoreContainer small:hover {
  text-decoration: underline;
}

.contentShortcutTiles {
  /* position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9d9d9d4f;
  opacity: 1;
  margin-right: 1%;
  padding: 0.3% 0.8%; */
  padding: 1%;
  padding-bottom: 1.5%;
  /* padding: 15px 27px; */
  position: relative;
}

.cs-tileTable {
  width: 100%;
  /* border-spacing: 0px 7px;
  border-collapse: separate; */
}

.cardOuterSpacing {
  /* padding: 0 2% 0 0; */
  /* padding: 0px 20px 0px 0px; */
  /* margin: 1%; */
  width: 32.1%;
  padding: 0.5% 0%;
}

.cs-tBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cs-tileRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Content Shortcut Home Page Card CSS End */

/*Major Topic Search CSS Start*/
.results-page-major-search-wrapper {
  margin-top: 20px;
}

.major-topic-search-wrapper {
  text-align: center;
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  color: #333;
}

.major-topic-search-button {
  background: url('../assets/green.png') no-repeat 0px 1px;
  background-size: 100%;
  border: none;
  margin-right: 0;
  outline: none;
  min-width: 55px;
  height: 33px;
  display: inline-block;
  padding: 13px 12px;
}

.major-topic-reset-button {
  background: url('../assets/yellow.png') no-repeat 0px 1px;
  background-size: 100%;
  border: none;
  margin-right: 0;
  outline: none;
  min-width: 55px;
  height: 33px;
  display: inline-block;
  padding: 13px 12px;
}

.major-topic-search-allCaps {
  font-size: 12px;
}

/*Major Topic Search CSS End*/
/*Raw Values  Page CSS Start */
.raw-values-data-title {
  color: #1076bc;
  font-weight: bold;
  font-size: 14px;
  display: inline;
  word-break: break-word;
  text-decoration: none;
}

.raw-values-doc-title {
  cursor: pointer;
}

.raw-values-expand-collapse {
  color: #333;
  padding-bottom: 10px;
}

.raw-values-expand-collapse:hover {
  text-decoration: underline;
  color: #333;
}

/*Raw Values  Page CSS End*/
/* Reports CSS start */
.report-content-wrapper {
  min-height: 300px;
  width: 96%;
}

.reportTitle {
  float: left;
  font-size: larger;
  font-family: 'J&J CircularTT-Medium';
  width: 100%;
  text-align: left;
}

.reportError {
  color: red;
}

.dateWrapper {
  float: left;
  width: 100%;
}

.datePanel {
  float: left;
  width: 50%;
  margin-top: 15px;
}

.reportPanel {
  width: 50%;
  float: right;
}

.datePanelLabel {
  text-align: left;
  margin-top: 5%;
  font-size: 14px;
}

.datePanelControl {
  text-align: left;
  margin-left: 50%;
  margin-top: -48px;
  font-size: 14px;
}

.enddatePanelLabel {
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
}

.enddatePanelControl {
  text-align: left;
  margin-left: 50%;
  margin-top: -48px;
  font-size: 14px;
}

.rptCriHeader {
  text-align: left;
  width: 50%;
  position: absolute;
  font-weight: bold;
}

.genRptHeader {
  text-align: right;
  width: 50%;
  position: absolute;
  font-weight: bold;
  margin-left: -13%;
}

.headerWrapper {
  width: 100%;
}

.reportLinkWSW {
  margin-top: 4%;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  color: #1076bc;
  font-size: 14px;
  text-decoration: none;
}

.reportLinks {
  margin-top: 1%;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
}

.reportLinks>a,
.reportLinkWSW>a {
  color: #1076bc;
  font-size: 14px;
  text-decoration: none;
}

.react-datepicker__triangle {
  margin-left: -170px !important;
}

.rptTable {
  width: 100%;
  contain: layout;
}

.rptDataPanel {
  width: 100%;
}

.dropdown-menu {
  /* position: relative!important; */
  bottom: 100%;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

/* Reports CSS end */
/* input[type="checkbox"]:focus-within{
  background-color: red !important;
  color: red;
} */

/* GetHelp and Contact-Us page css start */
.upperCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000024;
  opacity: 1;
  padding: 10px 60px 20px 30px;
  margin: 10px 0px 0px 0px;
}

.lowerCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 10px 60px 40px 30px;
  margin: 10px 0px 0px 0px;
}

.helpContentInnerWrapper a {
  color: #FF8200;
  cursor: pointer;
  text-decoration: none;
}

.listIcon {
  margin: 0px 10px 4px 0px;
}

.listing {
  padding: 0px 0px 0px 30px;
}

.listing a:hover {
  color: #0074B0;
}

.upperCard p {
  font-size: 14px;
  opacity: 1;
  /* margin: 20px 0px 0px 0px; */
}

.contactusLink {
  color: #FF8000;
  cursor: pointer;
}

.contactusLink:hover {
  text-decoration: underline;
}

.contactContentPart {
  font-size: 14px;
  opacity: 1;
  margin: 20px 0px 0px 0px;
  color: #030506;
}

.listingContent {
  margin: 0px 0px 8px 0px;
}

.listing a {
  color: #1592E6;
}

.contactusContent {
  color: #030506;
}

.contentlisting {
  /* color: #030506; */
  color: #0D80B5;
}

.contentlisting:hover {
  /* color: #0074B0; */
  text-decoration: underline;
}

/* .contactBG{
  background: transparent url(../assets/two-content-business-partners-discussing-issue.png) no-repeat 0 0 padding-box;
  background-position: right top;
  background-position-y: 55px;
  margin-right: -68px;
  background-size: 450px;
} */
.knowledgeExpertsLinks:hover {
  color: #1592E6 !important;
  text-decoration: underline;
}

/* GetHelp and Contact-Us page css end */

/*Navigation CSS Starts*/
.navigation-menu-wrapper {
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 50px;
  background: #fff;
  border-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  font-family: 'J&J CircularTT-Medium';
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0 0%;
}

.navigation-main-menu-wrapper {
  display: flex;
  list-style: none;
  flex-direction: row;
  /* color:#888B8D; */
  color: #7d7a7a;
  font-size: 15px;
  width: 99%;
  align-items: self-end;
  justify-content: space-between;
  font-weight: lighter;
  margin-bottom: 0;
}

.navigation-main-menu-wrapper li {
  align-content: center;
  cursor: pointer;
}

.navigation-main-menu-wrapper li a:hover {
  color: #FF8000;
  text-decoration: none;
  background: #ffff;
}

.navigation-main-menu-wrapper a {
  color: #2a498a;
  text-decoration: none;
}

.navigation-main-menu-wrapper li a:active {
  color: #FF8000;
}

.navigation-divider {
  height: 50%;
  border: 1px solid #00000029;
  margin-bottom: 10px;
}

.navigation-active-indicator {
  border-bottom: 4px solid #FF8000;
  padding-top: 10px;
}

.navigation-inactive-indicator {
  padding-top: 14px;
}

.navigation-username-wrapper {
  color: #333;
  border: 2px solid #1DA1E5;
  border-radius: 5px;
}

.navigation-username-inner-wrapper {
  padding: 0 5px 0 5px;
  min-width: 170px;
}

.navigation-sub-menu-wrapper {
  list-style: none;
  position: absolute;
  z-index: 100;
  text-align: left;
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 2px solid #1DA1E5;
  border-radius: 0 0 5px 5px;
  padding: 0.7% 0 1.2% 0;
  margin-top: -1px;
}

.navigation-sub-menu-wrapper li {
  color: #7d7a7a;
  padding-left: 6%;
}

.navigation-sub-menu-wrapper li:hover {
  background-color: #f2f2f2;
}

.navigation-username-image-wrapper {
  padding: 0 5px;
  /* width: 12%; */
  float: right;
}

.navigation-username-image-wrapper img {
  transition: all 0.5s ease-in 0s;
}

.navigation-menu-alerts-sub-wrapper {
  /* border: 2px solid #1DA1E5; */
  border-radius: 5px;
}

.navigation-menu-alerts-sub-inner-wrapper {
  min-width: 120px;
  padding: 0 5px 0 1px;
}

.navigation-alerts-sub-menu-wrapper {
  list-style: none;
  position: absolute;
  z-index: 100;
  text-align: left;
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.7% 0 1.2% 0;
  border: 1px solid #1DA1E5;
  width: 254px;
}

.navigation-alerts-sub-menu-wrapper li {
  color: #7d7a7a;
  padding-left: 6%;
}

.navigation-alerts-sub-menu-wrapper li:hover {
  background-color: #f2f2f2;
}

/*Navigation CSS Ends*/
/*Pop Up CSS Start*/
.popup-cancel-btn-wrapper {
  padding: 10px 10px 0 0;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: ease 0.25s all;
  font-size: 20px;
  font-family: Arial, Sans-Serif;
  text-align: center;
  line-height: 100%;
  cursor: pointer;
}

.popup-header-icon-wrapper {
  width: 55px;
  height: 55px;
}

.popup-transition {
  transition: transform 0.1s ease-in-out;
  transform: initial;
}

.modal-dialog-scrollable .modal-content {
  max-height: fit-content !important;
}

/*Pop Up CSS End*/
/*What's New For You CSS Start*/
.wnfy-outer-wrapper {
  font-family: 'J&J CircularTT-Medium';
  margin: 0% 1% 1% 1%;
  /* margin-top:-15px; */
}

.WNFY-WOAV-RV-QH-outer-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
}

/* .WNFY-WOAV-outer-wrapper {
  width: calc(66.66% - 0.5%);
}

.rv-qh-outer-wrapper {
  width: calc(33.33% - 0.5%);
} */

.rv-qh-outer-wrapper-tablet {
  display: flex;
  flex-direction: row;
}

.rv-outer-wrapper {
  font-family: 'J&J CircularTT-Medium';
  margin: 0% 1% 0% 1%;
  /* margin-top:-15px; */
}

.qh-outer-wrapper {
  font-family: 'J&J CircularTT-Medium';
  margin: 0% 1% 0% 1%;
  /* margin-top:-15px; */
}

.wnfy-main-header {
  font-size: 22px;
  cursor: default;
}

.wnfy-main-header-desktop {
  color: white;
  font-size: 18px;
  cursor: default;
}

.wnfy-view-more-header-text {
  font-size: 16px;
  margin-right: 10px;
}

.wnfy-view-more-header-text-desktop {
  color: white;
  font-size: 16px;
  margin-right: 10px;
}

.wnfy-inner-wrapper {
  color: #333;
  padding: 0.7% 0% 0 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}
.wnfy-wrapper-desktop{
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  border:1px solid rgba(112, 112, 112, 0.21);
}

.rv-qh-inner-wrapper {
  color: #333;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  border:1px solid rgba(112, 112, 112, 0.21);
}

.rv-qh-inner-wrapper-desktop {
  color: #333;
  padding: 2px 0% 0 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  
}

.wnfy-header-section {
  border-bottom: 1px solid #EFEFEF;
  padding: 0.5% 1%;
  text-align: left;
  cursor: pointer;
}

.wnfy-header-section-desktop {
  background: #2a498a;
  border-bottom: 1px solid #EFEFEF;
  height: 42px;
  padding: 8px 20px;
  text-align: left;
  cursor: pointer;
}

.wnfy-header-display-flex-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wnfy-header-display-flex-section>.wnfy-main-header {
  line-height: normal;
}

.rv-qh-header-section {
  border-bottom: 1px solid #EFEFEF;
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.rv-qh-header-section-desktop {
  border-bottom: 1px solid #EFEFEF;
  height: 42px;
  padding: 8px 20px;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #2a498a;
}

.wnfy-header-text {
  font-size: 14px;
  cursor: default;
}

.wnfy-header-text-desktop {
  color:white;
  font-size: 14px;
  cursor: default;
}

.rv-view-more-wrapper,
.wnfy-view-more-wrapper {
  float: right;
  padding: 0.0% 0.3% 0 0.0%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  /* padding: 0px 30px; */
  /* padding: 0% 1.5% 0% 0%; */
  flex-direction: row;
  /* width: 20%; */
  width: 25%;
  justify-content: space-between;
}

.rv-view-more-wrapper {
  width: auto;
}

.wnfy-header-text:hover {
  text-decoration: underline;
}

.rv-view-more-wrapper img,
.wnfy-view-more-wrapper img {
  width: 11px;
  height: 14px;
}

.wnfy-no-product-content-section {
  padding: 1%;
  text-align: left;
  color: #333;
  font-size: 14px;
}

.wnfy-content-section {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  padding: 1%;
}

.rv-qh-content-section {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
  max-height: 145px;
  overflow-y: auto;
}

.wnfy-tile-wrapper-desktop {
  opacity: 1;
  /* margin:0.8%; */
  width: 32.1%;
  height: 264px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-family: 'J&J CircularTT-Medium';
}

.wnfy-tile-wrapper {
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  /* margin:0.8%; */
  width: 32.1%;
  height: 300px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-family: 'J&J CircularTT-Medium';
}

.wnfy-expand-collapse {
  padding: 0 1%;
}

.tooltip-bottom {
  position: absolute;
  background: #6E7987;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
  width: 30%;
}

.tooltip-bottom::after {
  right: 4px;
  left: auto;
  margin: 0;
}

.info-icon-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: -10px;
}

.wnfy-preview-wrapper {
  text-align: left;
  padding: 1% 4%;
  height: 46%;
}

/* .wnfy-preview-wrapper svg{
  width: 60% !important;
  height: auto !important;
} */
.wnfy-button-wrapper {
  text-align: left;
  padding: 1% 0%;
}

.wnfy-button-wrapper button {
  color: #0074B0;
  height: 25px;
  min-width: 70px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #f2f2f2;
}

.wnfy-button-wrapper button:hover {
  background: #0074B0;
  color: #fff;
}

.wnfy-citation-wrapper {
  text-align: left;
  height: 43%;
  padding: 1% 4%;
}

.wnfy-content-loader {
  margin: auto;
}

.wnfy-content-citation-wrapper {
  display: flex;
  flex-direction: column;
}

.wnfy-content-citation-wrapper a {
  text-decoration: none;
  color: #0074B0;
}

.wnfy-content-citation-wrapper a:hover {
  color: #0074B0;
  text-decoration: underline
}

.wnfy-content-citation-wrapper a span:hover {
  text-decoration: underline;
  color: #0074B0;
}

.wnfy-results-contents-wrapper {
  margin: 1% 1.2% 0 1%;
}

.wnfy-results-title-wrapper {
  display: flex;
  color: #333;
  font-family: 'J&J CircularTT-Medium';
}

.wnfy-results-list-wrapper {
  /*height:100vh;
    overflow-y: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 5px 8px 2px #00000029; 
    opacity: 1;*/
  margin: 0% 0 1% 0;
  font-family: 'J&J CircularTT-Medium';
}

.wnfyResultsContentWrapper {
  display: flex;
  text-align: left;
  padding: 0% 0 1% 0%;
  flex-direction: column;
  width: 100%;
}

.wnfy-results-title-text {
  font-size: 20px;
  text-align: left;
  width: 30%;
  /* width: 50%; */
  padding: 0.5% 1%;
}

.wnfy-results-count-wrapper {
  padding: 0.5% 0%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 85%;
  /* width: 50%; */
}

.wnfy-results-global-features-wrapper {
  margin-top: 0.8%;
}

.wnfy-results-product-wrapper {
  font-family: 'J&J CircularTT-Medium';
  background: #F4F4F4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  height: 61px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ease 0.5s all;
}

.wnfy-product-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-right: auto;
}

.wnfy-product-wrapper-with-margin {
  margin-left: auto;
}

.wnfy-results-product-inner-wrapper {
  padding: 0% 1% 0% 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  width: 100%;
}

.wnfy-select-product-wrapper {
  border-radius: 4px;
  background: #FFFFFF;
  height: 37px;
  box-shadow: 0px 1px 3px #00000029;
  color: #0074B0;
  min-width: 121px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  cursor: pointer;
  padding: 0 1%;
}

.wnfy-select-product-wrapper:hover {
  color: #fff;
  background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.wnfy-product-selected {
  color: #fff;
  background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.wnfy-results-time-cal-wrapper {
  font-family: 'J&J CircularTT-Medium';
  color: #FF4747;
  font-size: 14px;
  background: #F6F6F6;
  height: 20px;
  width: 13%;
  text-align: left;
  padding: 0 0 0 1%;
}

.wnfy-margin-between-citations {
  margin: 1% 0;
}

.wnfy-result-navigate-arrow {
  width: 24px;
  /* height: 20px; */
  /* background: #D9D9D9 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  /* opacity: 0.42; */
  background: rgba(0, 116, 176, 0.42) 0% 0% no-repeat padding-box;
  padding: 33%;
  display: inherit;
}

.wnfy-select-nav-button-wrapper {
  /* height: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer; */
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-content: center;
  justify-content: center;
  /* align-items: center; */
}

/*What's New For You CSS End*/
/*Global Search Features CSS Start*/
.global-features-wrapper {
  height: 80px;
  background: transparent linear-gradient(267deg, #69AAC7 0%, #82B2DC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  /* background: linear-gradient(267deg,#83B2DD,#68AAC7); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.global-features-inner-wrapper {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.5%;
  font-family: 'J&J CircularTT-Medium';
}

.global-feature-tile {
  border-radius: 7px;
  cursor: pointer;
  /* background: transparent url('../assets/Rectangle-7909.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 41px;
  widows: 41px;
  border-radius:7px; */
}

.global-feature-tile-img {
  background: rgba(255, 255, 255, 0.51) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-feature-tile-img-tab-mob {
  background: #7FB1DA 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-feature-tile-img:hover {
  background: #FF8000 0% 0% no-repeat padding-box;
}

.global-feature-tile-img-hov {
  background: #FF8000 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .global-feature-tile img{
  height: 41px;
  width: 41px;
  cursor: pointer;
} */
.global-feature-tile-text {
  min-width: 84px;
  font-size: 14px;
  color: #FFFFFF;
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .global-feature-tile-text {
    min-width: 78px;
    font-size: 12px;
  }
}

/*Global Search Features CSS End*/
/* Content Shortcut Administration and Dosages Tile CSS Start */
.contentShortcutInnerWapper {
  font-family: 'J&J CircularTT-Medium' !important;
  /* cursor: pointer; */
}

.cardContainer {
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9D9D9D4F;
  opacity: 1;
  height: 65px;
  cursor: pointer;
  /* padding: 15px; */
  /* height: 84px;
  display: grid; */
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 100px;
  align-items: flex-end; */
}

.cardInnerWapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 4.55% 0%; */
  /* padding-top: 2%; */
}

.card-leftPart {
  letter-spacing: 0px;
  color: #0074B0;
  opacity: 1;
  text-align: left;
  padding: 0% 4.55%;
  line-height: 16px;
}

.card-rightPart {
  /* background: #D9D9D9 0% 0% no-repeat padding-box; */
  /* background: rgba(217, 217, 217, 0.35) 0% 0% no-repeat padding-box;
  ;
  box-shadow: 0px 3px 6px #00000029; */
  border-radius: 2px;
  /* opacity: 0.42; */
  margin: 0px 11px;
  padding: 0px 6px;
  cursor: pointer;
}

.cs-downArrow {
  transform: rotate(90deg);
}

.cs-modalBody-listing {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin: 0% 12% 0% 4%;
  text-align: left;
  font-size: 14px;
  flex-wrap: wrap;
}

.dosagesTypes {
  /* text-align: center; */
  color: #1C75BC;
  margin: 2% 0% 0% 15%;
}

.dosagesText {
  margin: 0% 10%;
  font-size: 13px;
  margin-bottom: 2px;
}

.dosagesSelection {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin: 5% 0%;
}

.docsTypeText {
  color: #1C75BC;
  margin: 2% 0% 0% 8%;
}

.dosageNA {
  font-size: 14px;
  text-align: left;
}

.card-iconsPart {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  z-index: 1;
}

/* Content Shortcut Administration and Dosages Tile CSS End */

/* Content Shortcut Labeling Documents Tile CSS Start */
.labelingDocuments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
}

.cs-ld-modalList {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 13px;
  list-style: none;
  /* width: 40%; */
}

/* Content Shortcut Labeling Documents Tile CSS End */

/* Content Shortcut Key Congress Literature Documents Tile CSS Start */
.congressText {
  text-align: left;
  font-size: 13px;
  color: #333;
}

/* Content Shortcut Key Congress Literature Documents Tile CSS End */

/* Content Shortcut Custom Pop-up CSS Start */
.cs-modalOuter {
  /* background: rgba(0, 0, 0, 0.75); */
  /* display: flex;
    align-items: flex-start; */
  position: fixed;
  /* height: 330vh;  90%*/
  height: 375vh;
  /* 100% */
  /* height: 350vh; */
  /* position: absolute; */
  background: #63666A;
  opacity: 0.78;
  border: 1px solid #707070;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 1.2rem;
}

.cs-modalInner {
  /* position: relative; */
  /* top: 42.5%; */
  /* top: 56.4%;
  left: 10.8%; */
  background-clip: padding-box;
  /* width: 79%; */
  margin: auto;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #9d9d9d4f;
  opacity: 1;
  /* margin-bottom: 20%; */
  /* new css */
  position: absolute;
  /* width: 76%; */
  z-index: 1;
  /* left: 22.15%; */
  left: 0;
  margin: 0% 1%;
  width: 98%;
}

.cs-modalInner-filter {
  /* position: relative; */
  /* top: 43%; */
  /* top: 57.2%;
  left: 6%; */
  background-clip: padding-box;
  /* width: 89%; */
  margin: auto;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #9d9d9d4f;
  opacity: 1;
  /* new css */
  position: absolute;
  /* width: 85.65%; */
  z-index: 1;
  /* left: 12.5%; */
  left: 0;
  margin: 0% 1%;
  width: 98%;
}

.cs-modalHeader {
  /* display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between; */
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  /* border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); */
}

.cs-modalTitle {
  font-size: 20px;
  color: #333;
}

.cs-modalButton {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 4px;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  /* background: transparent linear-gradient(90deg, #61779C 0%, #7CC7BF 100%) 0% 0% no-repeat padding-box; */
  letter-spacing: 0.01px;
  color: #0074B0;
  /* color: #FFFFFF; */
  font-size: 14px;
  /* margin: 0% 2%; */
  /* width: 12.8%;
  height: 37px; */
  border-style: none;
  text-transform: uppercase;
  padding: 5px 12px;
  /* margin-top: -5px; */
  cursor: pointer;
}

.cs-modalButton:hover {
  color: #FFFFFF;
  background: transparent linear-gradient(90deg, #61779C 0%, #7CC7BF 100%) 0% 0% no-repeat padding-box;
}

.cs-modalButton:active {
  color: #FFFFFF;
  background: transparent linear-gradient(90deg, #61779C 0%, #7CC7BF 100%) 0% 0% no-repeat padding-box;
  border-style: none;
}

.cs-modalButton:focus-visible {
  outline: none;
}

.cs-modalNote {
  color: #F80000;
  text-align: left;
  font-size: 13px;
}

.main-active {
  color: #FFFFFF;
  background: transparent linear-gradient(90deg, #61779C 0%, #7CC7BF 100%) 0% 0% no-repeat padding-box;
  border-style: none;
}

.cs-modalList {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 13px;
  list-style: none;
}

.cs-modalListing {
  color: #0074B0;
  line-height: 30px;
  width: 100%;
  word-break: break-word;
}

.cs-modalListing::before {
  content: "•";
  color: #333;
  display: inline-block;
  width: 1em;
  margin-left: -1em
}

.cs-modalLink {
  text-decoration: none;
  color: #0074B0;
}

.cs-modalLink:hover {
  text-decoration: underline;
  color: #1DA1E5;
}

.cs-tooltip-bottom {
  position: absolute;
  padding: 6px 4px 3px 11px;
  color: #fff;
  background: #0074B0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  top: -34px;
  width: 100%;
  /* bottom: -35px; */
}

.cs-tooltip-bottom::after {
  right: 4px;
  left: auto;
  margin: 0;
}

.cs-tile-wrapper {
  position: relative;
  display: flex;
  z-index: 10000;
}

.cs-tooltip-uparrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 13px solid #0074B0;
  position: absolute;
  top: -47px;
  right: 15px;
  /* bottom: 60px; */
}

.cs-modalButtonOuter {
  margin: 0.5% 1%;
}

.cs-product-wrapper {
  font-family: 'J&J CircularTT-Medium';
  opacity: 1;
  /* height: 61px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  /* justify-content: center; */
  transition: ease 0.5s all;
}

.cs-product-inner-wrapper {
  padding: 0% 1% 0% 1%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  font-size: 14px;
  width: 100%;
}

.cs-modal-product-wrapper {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  width: 100%;
  /* margin-right: auto; */
  /* flex-wrap: wrap; */
}

.cs-select-product-wrapper {
  border-radius: 4px;
  background: #FFFFFF;
  height: 37px;
  box-shadow: 0px 1px 3px #00000029;
  color: #0074B0;
  min-width: 121px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  cursor: pointer;
  padding: 0 1%;
  margin: auto;
}

.cs-select-product-wrapper:hover {
  color: #fff;
  background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.cs-product-selected {
  color: #fff;
  background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.cs-tile-i-icon {
  margin: 1.5% 3% 0% 0%;
}

/*  */
/* @media only screen and (min-width: 1300px) and (max-width: 1400px) {
  @media screen and (min-height: 580px) and (max-height: 600px) {
    .cs-modalOuter{
      height: 335vh !important;
    }
  }
  @media screen and (min-height: 600px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 323vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 316vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 308vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 665px) {
    .cs-modalOuter{
      height: 300vh !important;
    }
  }
  @media screen and (min-height: 665px) and (max-height: 680px) {
    .cs-modalOuter{
      height: 295vh !important;
    }
  }
  @media screen and (min-height: 680px) and (max-height: 690px) {
    .cs-modalOuter{
      height: 287vh !important;
    }
  }
  @media screen and (min-height: 690px) and (max-height: 705px) {
    .cs-modalOuter{
      height: 282vh !important;
    }
  }
  @media screen and (min-height: 705px) and (max-height: 720px) {
    .cs-modalOuter{
      height: 276vh !important;
    }
  }
  @media screen and (min-height: 720px) and (max-height: 735px) {
    .cs-modalOuter{
      height: 272vh !important;
    }
  }
  @media screen and (min-height: 735px) and (max-height: 750px) {
    .cs-modalOuter{
      height: 265vh !important;
    }
  }
  @media screen and (min-height: 750px) and (max-height: 770px) {
    .cs-modalOuter{
      height: 259vh !important;
    }
  }
}
@media only screen and (min-width: 1900px) and (max-width: 2000px) {
  @media screen and (min-height: 610px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 340vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 334vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 326vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 660px) {
    .cs-modalOuter{
      height: 321vh !important;
    }
  }
  @media screen and (min-height: 660px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 316vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 685px) {
    .cs-modalOuter{
      height: 309vh !important;
    }
  }
  @media screen and (min-height: 685px) and (max-height: 700px) {
    .cs-modalOuter{
      height: 303vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 715px) {
    .cs-modalOuter{
      height: 297vh !important;
    }
  }
  @media screen and (min-height: 715px) and (max-height: 730px) {
    .cs-modalOuter{
      height: 290vh !important;
    }
  }
  @media screen and (min-height: 730px) and (max-height: 745px) {
    .cs-modalOuter{
      height: 284vh !important;
    }
  }
  @media screen and (min-height: 745px) and (max-height: 760px) {
    .cs-modalOuter{
      height: 279vh !important;
    }
  }
  @media screen and (min-height: 760px) and (max-height: 770px) {
    .cs-modalOuter{
      height: 275vh !important;
    }
  }
  @media screen and (min-height: 770px) and (max-height: 780px) {
    .cs-modalOuter{
      height: 270vh !important;
    }
  }
  @media screen and (min-height: 780px) and (max-height: 795px) {
    .cs-modalOuter{
      height: 267vh !important;
    }
  }
  @media screen and (min-height: 795px) and (max-height: 810px) {
    .cs-modalOuter{
      height: 262vh !important;
    }
  }
  @media screen and (min-height: 810px) and (max-height: 825px) {
    .cs-modalOuter{
      height: 257vh !important;
    }
  }
  @media screen and (min-height: 825px) and (max-height: 835px) {
    .cs-modalOuter{
      height: 254vh !important;
    }
  }
  @media screen and (min-height: 835px) and (max-height: 850px) {
    .cs-modalOuter{
      height: 249vh !important;
    }
  }
  @media screen and (min-height: 850px) and (max-height: 860px) {
    .cs-modalOuter{
      height: 244vh !important;
    }
  }
  @media screen and (min-height: 860px) and (max-height: 870px) {
    .cs-modalOuter{
      height: 242vh !important;
    }
  }
  @media screen and (min-height: 870px) and (max-height: 880px) {
    .cs-modalOuter{
      height: 240vh !important;
    }
  }
  @media screen and (min-height: 880px) and (max-height: 890px) {
    .cs-modalOuter{
      height: 237vh !important;
    }
  }
  @media screen and (min-height: 890px) and (max-height: 900px) {
    .cs-modalOuter{
      height: 235vh !important;
    }
  }
  @media screen and (min-height: 900px) and (max-height: 915px) {
    .cs-modalOuter{
      height: 230vh !important;
    }
  }
  @media screen and (min-height: 915px) and (max-height: 930px) {
    .cs-modalOuter{
      height: 227vh !important;
    }
  }
  @media screen and (min-height: 930px) and (max-height: 945px) {
    .cs-modalOuter{
      height: 224vh !important;
    }
  }
  @media screen and (min-height: 945px) and (max-height: 960px) {
    .cs-modalOuter{
      height: 220vh !important;
    }
  }
  @media screen and (min-height: 960px) and (max-height: 975px) {
    .cs-modalOuter{
      height: 217vh !important;
    }
  }
  @media screen and (min-height: 975px) and (max-height: 990px) {
    .cs-modalOuter{
      height: 214vh !important;
    }
  }
  @media screen and (min-height: 990px) and (max-height: 1005px) {
    .cs-modalOuter{
      height: 210vh !important;
    }
  }
  @media screen and (min-height: 1005px) and (max-height: 1020px) {
    .cs-modalOuter{
      height: 208vh !important;
    }
  }
  @media screen and (min-height: 1020px) and (max-height: 1035px) {
    .cs-modalOuter{
      height: 205vh !important;
    }
  }
  @media screen and (min-height: 1035px) and (max-height: 1050px) {
    .cs-modalOuter{
      height: 202vh !important;
    }
  }
  @media screen and (min-height: 1050px) and (max-height: 1065px) {
    .cs-modalOuter{
      height: 199vh !important;
    }
  }
  @media screen and (min-height: 1065px) and (max-height: 1080px) {
    .cs-modalOuter{
      height: 195vh !important;
    }
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  @media screen and (min-height: 580px) and (max-height: 600px) {
    .cs-modalOuter{
      height: 337vh !important;
    }
  }
  @media screen and (min-height: 600px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 325vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 318vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 310vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 302vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 685px) {
    .cs-modalOuter{
      height: 295vh !important;
    }
  }
  @media screen and (min-height: 685px) and (max-height: 700px) {
    .cs-modalOuter{
      height: 288vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 715px) {
    .cs-modalOuter{
      height: 282vh !important;
    }
  }
  @media screen and (min-height: 715px) and (max-height: 730px) {
    .cs-modalOuter{
      height: 277vh !important;
    }
  }
  @media screen and (min-height: 730px) and (max-height: 750px) {
    .cs-modalOuter{
      height: 270vh !important;
    }
  }
  @media screen and (min-height: 750px) and (max-height: 765px) {
    .cs-modalOuter{
      height: 264vh !important;
    }
  }
  @media screen and (min-height: 765px) and (max-height: 775px) {
    .cs-modalOuter{
      height: 258vh !important;
    }
  }
  @media screen and (min-height: 775px) and (max-height: 795px) {
    .cs-modalOuter{
      height: 253vh !important;
    }
  }
  @media screen and (min-height: 795px) and (max-height: 810px) {
    .cs-modalOuter{
      height: 248vh !important;
    }
  }
  @media screen and (min-height: 810px) and (max-height: 825px) {
    .cs-modalOuter{
      height: 243vh !important;
    }
  }
  @media screen and (min-height: 825px) and (max-height: 850px) {
    .cs-modalOuter{
      height: 238vh !important;
    }
  }
  @media screen and (min-height: 850px) and (max-height: 865px) {
    .cs-modalOuter{
      height: 232vh !important;
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  @media screen and (min-height: 580px) and (max-height: 600px) {
    .cs-modalOuter{
      height: 335vh !important;
    }
  }
  @media screen and (min-height: 600px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 326vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 319vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 311vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 660px) {
    .cs-modalOuter{
      height: 305vh !important;
    }
  }
  @media screen and (min-height: 660px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 300vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 685px) {
    .cs-modalOuter{
      height: 295vh !important;
    }
  }
  @media screen and (min-height: 685px) and (max-height: 700px) {
    .cs-modalOuter{
      height: 288vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 715px) {
    .cs-modalOuter{
      height: 282vh !important;
    }
  }
  @media screen and (min-height: 715px) and (max-height: 730px) {
    .cs-modalOuter{
      height: 277vh !important;
    }
  }
  @media screen and (min-height: 730px) and (max-height: 745px) {
    .cs-modalOuter{
      height: 272vh !important;
    }
  }
  @media screen and (min-height: 745px) and (max-height: 760px) {
    .cs-modalOuter{
      height: 266vh !important;
    }
  }
  @media screen and (min-height: 760px) and (max-height: 775px) {
    .cs-modalOuter{
      height: 260vh !important;
    }
  }
  @media screen and (min-height: 775px) and (max-height: 795px) {
    .cs-modalOuter{
      height: 255vh !important;
    }
  }
  @media screen and (min-height: 795px) and (max-height: 810px) {
    .cs-modalOuter{
      height: 250vh !important;
    }
  }
  @media screen and (min-height: 810px) and (max-height: 825px) {
    .cs-modalOuter{
      height: 244vh !important;
    }
  }
  @media screen and (min-height: 825px) and (max-height: 835px) {
    .cs-modalOuter{
      height: 242vh !important;
    }
  }
  @media screen and (min-height: 835px) and (max-height: 850px) {
    .cs-modalOuter{
      height: 237vh !important;
    }
  }
  @media screen and (min-height: 850px) and (max-height: 870px) {
    .cs-modalOuter{
      height: 232vh !important;
    }
  }
  @media screen and (min-height: 870px) and (max-height: 890px) {
    .cs-modalOuter{
      height: 227vh !important;
    }
  }
  @media screen and (min-height: 890px) and (max-height: 900px) {
    .cs-modalOuter{
      height: 223vh !important;
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .cs-modalOuter{width: 106%;}
  @media screen and (min-height: 500px) and (max-height: 515px) {
    .cs-modalOuter{
      height: 390vh !important;
    }
  }
  @media screen and (min-height: 515px) and (max-height: 530px) {
    .cs-modalOuter{
      height: 378vh !important;
    }
  }
  @media screen and (min-height: 530px) and (max-height: 550px) {
    .cs-modalOuter{
      height: 365vh !important;
    }
  }
  @media screen and (min-height: 550px) and (max-height: 570px) {
    .cs-modalOuter{
      height: 352vh !important;
    }
  }
  @media screen and (min-height: 570px) and (max-height: 590px) {
    .cs-modalOuter{
      height: 340vh !important;
    }
  }
  @media screen and (min-height: 590px) and (max-height: 610px) {
    .cs-modalOuter{
      height: 328vh !important;
    }
  }
  @media screen and (min-height: 610px) and (max-height: 630px) {
    .cs-modalOuter{
      height: 319vh !important;
    }
  }
  @media screen and (min-height: 630px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 309vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 300vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 690px) {
    .cs-modalOuter{
      height: 290vh !important;
    }
  }
  @media screen and (min-height: 690px) and (max-height: 705px) {
    .cs-modalOuter{
      height: 286vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 720px) {
    .cs-modalOuter{
      height: 278vh !important;
    }
  }
}
@media (min-width: 1600px) and (max-width: 1700px) {
  @media screen and (min-height: 580px) and (max-height: 600px) {
    .cs-modalOuter{
      height: 340vh !important;
    }
  }
  @media screen and (min-height: 600px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 329vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 320vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 314vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 305vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 685px) {
    .cs-modalOuter{
      height: 298vh !important;
    }
  }
  @media screen and (min-height: 685px) and (max-height: 700px) {
    .cs-modalOuter{
      height: 290vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 715px) {
    .cs-modalOuter{
      height: 285vh !important;
    }
  }
  @media screen and (min-height: 715px) and (max-height: 730px) {
    .cs-modalOuter{
      height: 280vh !important;
    }
  }
  @media screen and (min-height: 730px) and (max-height: 745px) {
    .cs-modalOuter{
      height: 274vh !important;
    }
  }
  @media screen and (min-height: 745px) and (max-height: 760px) {
    .cs-modalOuter{
      height: 268vh !important;
    }
  }
  @media screen and (min-height: 760px) and (max-height: 775px) {
    .cs-modalOuter{
      height: 262vh !important;
    }
  }
  @media screen and (min-height: 775px) and (max-height: 795px) {
    .cs-modalOuter{
      height: 257vh !important;
    }
  }
  @media screen and (min-height: 795px) and (max-height: 810px) {
    .cs-modalOuter{
      height: 250vh !important;
    }
  }
  @media screen and (min-height: 810px) and (max-height: 820px) {
    .cs-modalOuter{
      height: 248vh !important;
    }
  }
  @media screen and (min-height: 820px) and (max-height: 830px) {
    .cs-modalOuter{
      height: 245vh !important;
    }
  }
  @media screen and (min-height: 830px) and (max-height: 840px) {
    .cs-modalOuter{
      height: 242vh !important;
    }
  }
  @media screen and (min-height: 840px) and (max-height: 850px) {
    .cs-modalOuter{
      height: 239vh !important;
    }
  }
  @media screen and (min-height: 850px) and (max-height: 870px) {
    .cs-modalOuter{
      height: 234vh !important;
    }
  }
  @media screen and (min-height: 870px) and (max-height: 890px) {
    .cs-modalOuter{
      height: 229vh !important;
    }
  }
  @media screen and (min-height: 890px) and (max-height: 900px) {
    .cs-modalOuter{
      height: 225vh !important;
    }
  }
}
@media only screen and (min-width: 1700px) and (max-width: 1900px) {
  @media screen and (min-height: 610px) and (max-height: 620px) {
    .cs-modalOuter{
      height: 337vh !important;
    }
  }
  @media screen and (min-height: 620px) and (max-height: 635px) {
    .cs-modalOuter{
      height: 328vh !important;
    }
  }
  @media screen and (min-height: 635px) and (max-height: 650px) {
    .cs-modalOuter{
      height: 322vh !important;
    }
  }
  @media screen and (min-height: 650px) and (max-height: 670px) {
    .cs-modalOuter{
      height: 311vh !important;
    }
  }
  @media screen and (min-height: 670px) and (max-height: 685px) {
    .cs-modalOuter{
      height: 305vh !important;
    }
  }
  @media screen and (min-height: 685px) and (max-height: 700px) {
    .cs-modalOuter{
      height: 298vh !important;
    }
  }
  @media screen and (min-height: 700px) and (max-height: 715px) {
    .cs-modalOuter{
      height: 292vh !important;
    }
  }
  @media screen and (min-height: 715px) and (max-height: 730px) {
    .cs-modalOuter{
      height: 285vh !important;
    }
  }
  @media screen and (min-height: 730px) and (max-height: 745px) {
    .cs-modalOuter{
      height: 280vh !important;
    }
  }
  @media screen and (min-height: 745px) and (max-height: 760px) {
    .cs-modalOuter{
      height: 275vh !important;
    }
  }
  @media screen and (min-height: 760px) and (max-height: 770px) {
    .cs-modalOuter{
      height: 270vh !important;
    }
  }
  @media screen and (min-height: 770px) and (max-height: 780px) {
    .cs-modalOuter{
      height: 267vh !important;
    }
  }
  @media screen and (min-height: 780px) and (max-height: 795px) {
    .cs-modalOuter{
      height: 262vh !important;
    }
  }
  @media screen and (min-height: 795px) and (max-height: 810px) {
    .cs-modalOuter{
      height: 257vh !important;
    }
  }
  @media screen and (min-height: 810px) and (max-height: 825px) {
    .cs-modalOuter{
      height: 252vh !important;
    }
  }
  @media screen and (min-height: 825px) and (max-height: 835px) {
    .cs-modalOuter{
      height: 250vh !important;
    }
  }
  @media screen and (min-height: 835px) and (max-height: 850px) {
    .cs-modalOuter{
      height: 245vh !important;
    }
  }
  @media screen and (min-height: 850px) and (max-height: 870px) {
    .cs-modalOuter{
      height: 240vh !important;
    }
  }
  @media screen and (min-height: 870px) and (max-height: 890px) {
    .cs-modalOuter{
      height: 235vh !important;
    }
  }
  @media screen and (min-height: 890px) and (max-height: 900px) {
    .cs-modalOuter{
      height: 232vh !important;
    }
  }
  @media screen and (min-height: 900px) and (max-height: 915px) {
    .cs-modalOuter{
      height: 227vh !important;
    }
  }
  @media screen and (min-height: 915px) and (max-height: 930px) {
    .cs-modalOuter{
      height: 225vh !important;
    }
  }
  @media screen and (min-height: 930px) and (max-height: 945px) {
    .cs-modalOuter{
      height: 221vh !important;
    }
  }
  @media screen and (min-height: 945px) and (max-height: 960px) {
    .cs-modalOuter{
      height: 217vh !important;
    }
  }
  @media screen and (min-height: 960px) and (max-height: 975px) {
    .cs-modalOuter{
      height: 214vh !important;
    }
  }
  @media screen and (min-height: 975px) and (max-height: 990px) {
    .cs-modalOuter{
      height: 210vh !important;
    }
  }
  @media screen and (min-height: 990px) and (max-height: 1005px) {
    .cs-modalOuter{
      height: 208vh !important;
    }
  }
  @media screen and (min-height: 1005px) and (max-height: 1020px) {
    .cs-modalOuter{
      height: 205vh !important;
    }
  }
  @media screen and (min-height: 1020px) and (max-height: 1035px) {
    .cs-modalOuter{
      height: 201vh !important;
    }
  }
  @media screen and (min-height: 1035px) and (max-height: 1050px) {
    .cs-modalOuter{
      height: 199vh !important;
    }
  }
  @media screen and (min-height: 1050px) and (max-height: 1065px) {
    .cs-modalOuter{
      height: 196vh !important;
    }
  }
  @media screen and (min-height: 1065px) and (max-height: 1080px) {
    .cs-modalOuter{
      height: 193vh !important;
    }
  }
} */
/* Content Shortcut Custom Pop-up CSS End */

/* Content Shortcut TA and Disease CSS Start */
.cs-signalImg {
  margin: 0 0 6px -10px;
  max-width: 100%;
}

.tandBodyContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 15%;
  /* justify-content: space-around; */
}

.tandListingContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.tandListingInnerWrapper {
  text-align: left;
  width: 31%;
}

.ddPart {
  width: 65%;
  float: left;
}

.tadPart {
  float: left;
}

.ddContainer {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ddLeftPart {
  width: 42%;
  float: left;
}

.ddRightPart {
  width: 50%;
}

@media only screen and (min-width: 600px) and (max-width: 1144px) {
  .ddPart {
    width: 100%;
    float: left;
  }

  /* .cs-modalOuter{
    height: 450vh;
  } */
}

@media only screen

/* and (min-width: 600px)  */
and (max-width: 600px) {
  .ddPart {
    width: 100%;
    float: left;
  }

  .ddLeftPart {
    width: 100%;
    float: left;
  }

  .ddRightPart {
    width: 100%;
  }

  /* .cs-modalOuter{
    height: 545vh;
  } */
}

/* Content Shortcut TA and Disease CSS End */
/* Common css start */
button {
  border-style: none;
}

button:active {
  border-style: none;
}

input:focus-visible {
  outline: none;
}

/* Common css end */
.pubMed-document-links {
  color: rgb(16, 118, 188);
  font-size: 18px;
  text-align: left;
  margin: 0 15%;
}
.safari-message-div{
  padding: 0 10px;
  margin-top:150px;
}
.safari-message-div > p{
  font-size: 22px;
}
.safari-message-div >button{
  font-size: 18px;
    font-weight: bold;
}
.safari-message-div > .download-google-chrome{
  color: rgb(227, 159, 0);
  border: 1px solid rgb(227, 159, 0);
  background: rgb(255, 255, 255);
  min-width: 70px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 5px;
    text-decoration: none;
}
.popTimeOut{
  margin-top: 50px !important;
}


@media screen and (min-width: 1000px) {
  .cardInnerWapper {
    border:1px solid rgba(112, 112, 112, 0.81);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding: 4.55% 0%; */
    /* padding-top: 2%; */
  }
  .card-leftPart{
    align-items: center;
    padding: 0% 3%;
  }
  .wnfy-outer-wrapper{
    display: flex;
    flex-direction: row;
  }
  .wnfy-inner-wrapper{
    width: calc(66.66%);
  }
  .wnfy-inner-wrapper-desktop{
    width: calc(66.66%);
  }
  .recentlyViewe-outer-wrapper,.queryHistory-outer-wrapper{
    width: calc(33.33%);
  }
  .wnfy-tile-wrapper{
    height: 250px;
  }
  .rv-qh-content-section{
    max-height: 264px;
    height: 264px;
  }
  .wnfy-view-more-right-arrow-icon-wrapper{
    background: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 2px;
    padding: 0 4px;
    cursor: pointer;
    border: 1px solid #2a498a;
  }
  span.wnfy-view-more-wrapper > div > .wnfy-view-more-right-arrow-icon-wrapper > img{
    height: 11px;
    width: 11px;
  }
  .WNFY-WOAV-outer-wrapper{
    width: 100%;
  }
  .ContentShortcutsRightPart {
    width: auto;
}
.wnfy-header-display-flex-section{
  height: 42px;
  padding: 5px 20px;
}
.wnfy-content-section {
  height: 264px;
}
.wnfy-tile-wrapper-border{
  border-right: 1px solid rgba(112, 112, 112, 0.21);
}
div.wnfy-preview-wrapper > a > img{
  border: 1px solid #0074B0;
}
}

@media only screen and (min-width: 1000px)  {
  @media screen and (min-height: 550px) and (max-height: 650px) {
    .recommended-contents-wrapper{
      min-height: 57vh !important;
    }
  }
  @media screen and (min-height: 651px) and (max-height: 750px) {
    .recommended-contents-wrapper{
      min-height: 63vh !important;
    }
  }
  @media screen and (min-height: 751px) and (max-height: 800px) {
    .recommended-contents-wrapper{
      min-height: 68vh !important;
    }
  }
  @media screen and (min-height: 801px) and (max-height: 850px) {
    .recommended-contents-wrapper{
      min-height: 70vh !important;
    }
  }

  @media screen and (min-height: 851px) and (max-height: 950px) {
    .recommended-contents-wrapper{
      min-height: 72vh !important;
    }
  }

  @media screen and (min-height: 951px) {
    .recommended-contents-wrapper{
      min-height: 100vh !important;
    }
  }
  
  /* @media screen and (min-width: 1000px) and (max-width: 1100px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }

    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 68px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 72px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 68px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1101px) and (max-width: 1170px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 38px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 38px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 66px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1171px) and (max-width: 1240px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 50px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 60px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 44px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 52px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 68px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1241px) and (max-width: 1300px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 60px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 42px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 38px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 55px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1301px) and (max-width: 1350px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 70px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 36px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 44px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 36px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 44px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 80px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1351px) and (max-width: 1400px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 72px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 75px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 70px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 80px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1401px) and (max-width: 1500px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 42px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 80px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 88px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 50px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 85px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 74px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 84px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1501px) and (max-width: 1600px) {
    .cs-modalInner-left{
      margin-left: 13px
    }
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 50px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 95px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 100px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 65px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 55px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 96px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 84px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 96px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }
  @media screen and (min-width: 1101px) and (max-width: 1170px) {
    .cs-left-r1-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r1-tile{
      text-align: center;
      padding-left: 38px;
    }
    .cs-right-r1-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-left-r2-tile{
      text-align: center;
      padding-left: 25px;
    }
    .cs-middle-r2-tile{
      text-align: center;
      padding-left: 38px;
    }
    .cs-right-r2-tile{
      text-align: center;
      padding-left: 45px;
    }
    .cs-left-r3-tile{
      text-align: center;
      padding-left: 31px;
    }
    .cs-middle-r3-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r3-tile{
      text-align: center;
      padding-left: 64px;
    }
    .cs-left-r4-tile{
      text-align: center;
      padding-left: 66px;
    }
    .cs-middle-r4-tile{
      text-align: center;
      padding-left: 40px;
    }
    .cs-right-r4-tile{
      text-align: center;
      padding-left: 64px;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1000px) {
    .cs-modalInner-left-pop {
      margin-left: 11px;
    }
    .cs-modalInner-right-pop{
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1001px) and (max-width: 1090px) {
    .cs-modalInner-left-pop {
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1001px) and (max-width: 1010px) {
    .cs-modalInner-right-pop{
      margin-left: 6px;
    }
  }

  @media screen and (min-width: 1011px) and (max-width: 1090px) {
    .cs-modalInner-right-pop{
      margin-left: 7px;
    }
  }

  @media screen and (min-width: 1091px) and (max-width: 1092px) {
    .cs-modalInner-left-pop {
      margin-left: 10px;
    }
    .cs-modalInner-right-pop{
      margin-left: 7px;
    }
  }

  @media screen and (min-width: 1093px) and (max-width: 1099px) {
    .cs-modalInner-left-pop {
      margin-left: 9px;
    }
    .cs-modalInner-right-pop{
      margin-left: 7px;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1100px) {
    .cs-modalInner-right-pop{
      margin-left: 7px;
    }
  }

  @media screen and (min-width: 1101px) and (max-width: 1200px) {
    .cs-modalInner-left-pop {
      margin-left: 10px;
    }
    .cs-modalInner-right-pop{
      margin-left: 8px;
    }
  }

  @media screen and (min-width: 1201px) and (max-width: 1265px) {
    .cs-modalInner-left-pop {
      margin-left: 11px;
    }
  }

  @media screen and (min-width: 1201px) and (max-width: 1251px) {
    .cs-modalInner-right-pop{
      margin-left: 8px;
    }
  }

  @media screen and (min-width: 1252px) and (max-width: 1265px) {
    .cs-modalInner-right-pop{
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1266px) {
    .cs-modalInner-left-pop {
      margin-left: 10px;
    }
    .cs-modalInner-right-pop{
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1267px) and (max-width: 1400px) {
    .cs-modalInner-left-pop {
      margin-left: 11px;
    }
    .cs-modalInner-right-pop{
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1401px) and (max-width: 1514px) {
    .cs-modalInner-left-pop {
      margin-left: 12px;
    }
  }

  @media screen and (min-width: 1401px) and (max-width: 1401px) {
    .cs-modalInner-right-pop{
      margin-left: 9px;
    }
  }

  @media screen and (min-width: 1402px) and (max-width: 1514px) {
    .cs-modalInner-right-pop{
      margin-left: 10px;
    }
  }

  @media screen and (min-width: 1515px) and (max-width: 1595px) {
    .cs-modalInner-left-pop {
      margin-left: 13px;
    }
    .cs-modalInner-right-pop{
      margin-left: 11px;
    }
  }

  @media screen and (min-width: 1596px) and (max-width: 1600px) {
    .cs-modalInner-left-pop {
      margin-left: 14px;
    }
    .cs-modalInner-right-pop{
      margin-left: 11px;
    }
  } */
} 
.imsResultsMenuHeader-deactive,
.imsResultsMenuHeader-tablet-deactive,
.pubmedResultsMenuHeader-tablet-deactive, 
.pubmedResultsMenuHeader-deactive,
.pubmedResultsMenuHeader-tablet{
    cursor: pointer;
    border: 1px solid #2a498a;
    color: #2a498a;
}

@media screen and (min-width: 600px) {
  div.card-leftPart > small > span.cs-name-bold{
    font-weight: bolder;
  }
}
.z-index-Share{
z-index: 10009 !important;
}
.z-index-Share-backdrop{
  z-index: 10008 !important;
}
.z-index-alertMessage{
  z-index: 10010 !important;
}
.z-index-alertMessage-backdrop{
  z-index: 10009 !important;
}
.z-index-rename,.z-index-create-notebook{
  z-index: 10008 !important;
}
.z-index-rename-backdrop, .z-index-create-notebook-backdrop{
  z-index: 10007 !important;
}
.z-index-popup-transition, .document-processing-contentPopUp, .document-processing-newthread, .authoring-disclaimer-model {
  z-index: 10012 !important;
}
.z-index-popup-transition-backdrop, .document-processing-contentPopUp-backdrop, .document-processing-newthread-backdrop{
  z-index: 10011 !important;
}
.document-processing-contentPopUp-backdrop.modal-backdrop.show, .document-processing-newthread-backdrop.modal-backdrop.show {
  opacity: 0.9;
}
.z-index-preview{
  z-index: 10002 !important;
}
.z-index-preview-backdrop{
  z-index: 10001 !important;
}

.z-index-milestone{
  z-index: 10004 !important;
}
.z-index-milestone-backdrop{
  z-index: 10003 !important;
}
.z-index-popTimeOut{
  z-index: 10015 !important;
}
.z-index-popTimeOut-backdrop{
  z-index: 10014 !important;
}

td>ul.ul-access-group{
  text-align: left;
}
.modal-body {
  padding: 1rem !important;
}

.greenlight-table-wrapper table{
  border-style: solid;
  border-color: rgba(243, 144, 29, 0.73);
}
.greenlight-table-wrapper table tbody tr td,.greenlight-table-wrapper table thead tr th {
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  border-color: rgba(243, 144, 29, 0.73);
}
.greenlight-table-wrapper table thead tr th{
  text-align:center;
}
.greenlight-table-wrapper table tbody tr td:first-child{
  text-align:center;
}
